import React from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Page,
  SectionHero,
  SectionCustomerLogos,
  SectionHowItWorks,
  SectionPortfolioLogos,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  SuccessStoryBanner,
  SectionExpandingTo,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/freedomlySocialShare.png';
import twitterImage from '../../assets/freedomlySocialShare.png';
import succesStoryBannerImage from '../../assets/tulus-success-story-banner.webp';
import css from './FreelancerFinlandPage.css';
import { propTypes } from '../../util/types';

export const FreelancerFinlandPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUser,
    currentUserFetchInProgress,
  } = props;

  // const [atTalentSection, setAtTalentSection] = useState();

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage(
    { id: 'FreelancerFinlandPage.schemaTitle' },
    { siteTitle }
  );
  const schemaDescription = intl.formatMessage({ id: 'FreelancerFinlandPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const sectionHowItWorksHeader = (
    <FormattedMessage id="FreelancerFinlandPage.sectionHowItWorksHeader" />
  );
  const heroTitle1 = <FormattedMessage id="FreelancerFinlandPage.title" />;
  const heroTitle2 = <FormattedMessage id="FreelancerFinlandPage.title2" />;
  const heroSubtitle = <FormattedMessage id="FreelancerFinlandPage.subTitle" />;

  const sectionHowItWorksCardTitle1 = <FormattedMessage id="FreelancerFinlandPage.cardTitle1" />;
  const sectionHowItWorksCardTitle2 = <FormattedMessage id="FreelancerFinlandPage.cardTitle2" />;
  const sectionHowItWorksCardTitle3 = <FormattedMessage id="FreelancerFinlandPage.cardTitle3" />;
  const sectionHowItWorksCTA = <FormattedMessage id="FreelancerFinlandPage.sectionHowItWorksCTA" />;

  const sectionHowItWorksCardDescription1 = (
    <FormattedMessage id="FreelancerFinlandPage.cardDescription1" />
  );
  const sectionHowItWorksCardDescription2 = (
    <FormattedMessage id="FreelancerFinlandPage.cardDescription2" />
  );
  const sectionHowItWorksCardDescription3 = (
    <FormattedMessage id="FreelancerFinlandPage.cardDescription3" />
  );

  const sectionHowItWorksInfoImageName = (
    <FormattedMessage id="FreelancerFinlandPage.infoImageName" />
  );
  const sectionHowItWorksInfoImageText1 = (
    <FormattedMessage id="FreelancerFinlandPage.infoImageText1" />
  );
  const sectionHowItWorksInfoImageText2 = (
    <FormattedMessage id="FreelancerFinlandPage.infoImageText2" />
  );
  const sectionHowItWorksInfoImageText3 = (
    <FormattedMessage id="FreelancerFinlandPage.infoImageText3" />
  );
  const sectionHowItWorksInfoImageText4 = (
    <FormattedMessage id="FreelancerFinlandPage.infoImageText4" />
  );

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[
        { url: `${config.canonicalRootURL}${facebookImage}`, width: 1200, height: 630 },
      ]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="LandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.heroContainer}>
                <SectionHero
                  history={history}
                  location={location}
                  heroTitle1={heroTitle1}
                  heroTitle2={heroTitle2}
                  heroSubtitle={heroSubtitle}
                  atLandingPage={false}
                />
              </div>
            </li>
            <li className={css.section}>
              <SectionCustomerLogos
                toShow={[
                  'peikko',
                  'olefit',
                  'eerikkila',
                  'corego',
                  'mobal',
                  'strongest',
                  'systam',
                  'zervant',
                ]}
              />
            </li>
            <li className={css.section}>
              <SectionHowItWorks
                header={sectionHowItWorksHeader}
                cardTitle1={sectionHowItWorksCardTitle1}
                cardTitle2={sectionHowItWorksCardTitle2}
                cardTitle3={sectionHowItWorksCardTitle3}
                cardDescription1={sectionHowItWorksCardDescription1}
                cardDescription2={sectionHowItWorksCardDescription2}
                cardDescription3={sectionHowItWorksCardDescription3}
                infoImageName={sectionHowItWorksInfoImageName}
                infoImageText1={sectionHowItWorksInfoImageText1}
                infoImageText2={sectionHowItWorksInfoImageText2}
                infoImageText3={sectionHowItWorksInfoImageText3}
                infoImageText4={sectionHowItWorksInfoImageText4}
                cta={sectionHowItWorksCTA}
                atLandingPage={false}
              />
            </li>
            <li className={css.section}>
              <SectionLocations />
            </li>
            <li className={css.section}>
              <SuccessStoryBanner
                imgSrc={succesStoryBannerImage}
                textId={'SuccessStoryBanner.tulusText'}
                fromId={'SuccessStoryBanner.tulusFrom'}
                companyId={'SuccessStoryBanner.tulusCompany'}
                pageName={'SuccessStoryTulusPage'}
                buttonTxtId={'SuccessStoryBanner.tulusCTAText'}
              />
            </li>
            <li className={css.section}>
              <SectionPortfolioLogos />
            </li>
            <li className={css.section}>
              <SectionExpandingTo />
            </li>
          </ul>
          <div className={css.contactSection}>
            <div className={css.contactSectionTitle}>
              <FormattedMessage id="SectionContactLandingPage.title" />
            </div>
            <div className={css.contactSectionSubTitle}>
              <FormattedMessage id="SectionContactLandingPage.subtitle" />
            </div>
            <NamedLink name="SignupPage" className={css.contactSectionButton}>
              <FormattedMessage id="SectionContactLandingPage.buttonText" />
            </NamedLink>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

FreelancerFinlandPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  currentUser: propTypes.user,
  currentUserFetchInProgress: bool.isRequired,

  usersAtSectionTalent: arrayOf(propTypes.user),
};

const mapStateToProps = state => {
  const { currentUser, currentUserFetchInProgress } = state.user;
  const { usersAtSectionTalent } = state.LandingPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    usersAtSectionTalent,
    currentUserFetchInProgress,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const FreelancerFinlandPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(FreelancerFinlandPageComponent);

// LandingPage.loadData = loadData;

export default FreelancerFinlandPage;
