import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  fetchcurrentOffPlatformTestimonial,
  leaveOffPlatformTestimonial,
  fetchProfileUserName,
} from '../../util/api';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const FETCH_USER_FULL_NAME_REQUEST = 'app/ProfilePage/FETCH_USER_FULL_NAME_REQUEST';
export const FETCH_USER_FULL_NAME_SUCCESS = 'app/ProfilePage/FETCH_USER_FULL_NAME_SUCCESS';
export const FETCH_USER_FULL_NAME_ERROR = 'app/ProfilePage/FETCH_USER_FULL_NAME_ERROR';

export const QUERY_CURRENT_OFF_PLATFORM_TESTIMONIAL_REQUEST =
  'app/ProfilePage/QUERY_CURRENT_OFF_PLATFORM_TESTIMONIAL_REQUEST';
export const QUERY_CURRENT_OFF_PLATFORM_TESTIMONIAL_SUCCESS =
  'app/ProfilePage/QUERY_CURRENT_OFF_PLATFORM_TESTIMONIAL_SUCCESS';
export const QUERY_CURRENT_OFF_PLATFORM_TESTIMONIAL_ERROR =
  'app/ProfilePage/QUERY_CURRENT_OFF_PLATFORM_TESTIMONIAL_ERROR';

export const LEAVE_OFF_PLATFORM_TESTIMONIAL_REQUEST =
  'app/ProfilePage/LEAVE_OFF_PLATFORM_TESTIMONIAL_REQUEST';
export const LEAVE_OFF_PLATFORM_TESTIMONIAL_SUCCESS =
  'app/ProfilePage/LEAVE_OFF_PLATFORM_TESTIMONIAL_SUCCESS';
export const LEAVE_OFF_PLATFORM_TESTIMONIAL_ERROR =
  'app/ProfilePage/LEAVE_OFF_PLATFORM_TESTIMONIAL_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userShowError: null,
  currentOffPlatformTestimonial: null,
  querycurrentOffPlatformTestimonialError: null,
  leaveOffPlatformTestimonialError: null,
  userFullName: null,
  userFullNameError: null,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case FETCH_USER_FULL_NAME_REQUEST:
      return { ...state, userFullNameError: null };
    case FETCH_USER_FULL_NAME_SUCCESS:
      return { ...state, userFullName: payload };
    case FETCH_USER_FULL_NAME_ERROR:
      return { ...state, userFullNameError: payload };

    case QUERY_CURRENT_OFF_PLATFORM_TESTIMONIAL_REQUEST:
      return { ...state, querycurrentOffPlatformTestimonialError: null };
    case QUERY_CURRENT_OFF_PLATFORM_TESTIMONIAL_SUCCESS:
      return { ...state, currentOffPlatformTestimonial: payload };
    case QUERY_CURRENT_OFF_PLATFORM_TESTIMONIAL_ERROR:
      return { ...state, querycurrentOffPlatformTestimonialError: payload };

    case LEAVE_OFF_PLATFORM_TESTIMONIAL_REQUEST:
      return { ...state, leaveOffPlatformTestimonialError: null };
    case LEAVE_OFF_PLATFORM_TESTIMONIAL_SUCCESS:
      return state;
    case LEAVE_OFF_PLATFORM_TESTIMONIAL_ERROR:
      return { ...state, leaveOffPlatformTestimonialError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const fetchUserFullNameRequest = () => ({
  type: FETCH_USER_FULL_NAME_REQUEST,
});

export const fetchUserFullNameSuccess = userFullName => ({
  type: FETCH_USER_FULL_NAME_SUCCESS,
  payload: userFullName,
});

export const fetchUserFullNameError = e => ({
  type: FETCH_USER_FULL_NAME_ERROR,
  error: true,
  payload: e,
});

export const querycurrentOffPlatformTestimonialRequest = () => ({
  type: QUERY_CURRENT_OFF_PLATFORM_TESTIMONIAL_REQUEST,
});

export const querycurrentOffPlatformTestimonialSuccess = testimonial => ({
  type: QUERY_CURRENT_OFF_PLATFORM_TESTIMONIAL_SUCCESS,
  payload: testimonial,
});

export const querycurrentOffPlatformTestimonialError = e => ({
  type: QUERY_CURRENT_OFF_PLATFORM_TESTIMONIAL_ERROR,
  error: true,
  payload: e,
});

export const leaveOffPlatformTestimonialRequest = () => ({
  type: LEAVE_OFF_PLATFORM_TESTIMONIAL_REQUEST,
});

export const leaveOffPlatformTestimonialSuccess = () => ({
  type: LEAVE_OFF_PLATFORM_TESTIMONIAL_SUCCESS,
});

export const leaveOffPlatformTestimonialError = e => ({
  type: LEAVE_OFF_PLATFORM_TESTIMONIAL_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const getUserFullName = userId => (dispatch, getState, sdk) => {
  if (typeof window !== 'undefined') {
    dispatch(fetchUserFullNameRequest());
    return fetchProfileUserName(userId.uuid)
      .then(response => {
        dispatch(fetchUserFullNameSuccess(response));
      })
      .catch(e => dispatch(fetchUserFullNameError(e)));
  }
};

export const querycurrentOffPlatformTestimonial = (userId, workExperienceId) => dispatch => {
  if (typeof window !== 'undefined') {
    dispatch(querycurrentOffPlatformTestimonialRequest());
    return fetchcurrentOffPlatformTestimonial(userId.uuid, workExperienceId.uuid)
      .then(response => {
        dispatch(querycurrentOffPlatformTestimonialSuccess(response.item));
      })
      .catch(e => dispatch(querycurrentOffPlatformTestimonialError(e)));
  }
};

export const postOffPlatformTestimonial = (
  freelancerId,
  workExperienceId,
  content,
  authorPosition,
  authorName
) => (dispatch, getState, sdk) => {
  dispatch(leaveOffPlatformTestimonialRequest());
  const body = { freelancerId, workExperienceId, content, authorPosition, authorName };
  const testimonialResponse = leaveOffPlatformTestimonial(body);
  Promise.resolve(testimonialResponse)
    .then(response => {
      dispatch(querycurrentOffPlatformTestimonialSuccess(response));
      dispatch(leaveOffPlatformTestimonialSuccess());
    })
    .catch(e => dispatch(leaveOffPlatformTestimonialError(storableError(e))));
};

export const loadData = (userId, workExperienceId) => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(showUser(userId)),
    dispatch(getUserFullName(userId)),
    dispatch(querycurrentOffPlatformTestimonial(userId, workExperienceId)),
  ]);
};
