import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';
import css from './FieldTextEditor.css';
const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

const CONTENT_MAX_LENGTH = 5000;

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    // ['link', 'image'],
    ['clean'],
  ],
};

class FieldTextEditorComponent extends Component {
  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputRootClass,
      quillClass,
      customErrorText,
      id,
      label,
      input,
      meta,
      onUnmount,
      isUncontrolled,
      inputRef,
      placeholder,
      ...rest
    } = this.props;
    /* eslint-enable no-unused-vars */
    if (label && !id) {
      throw new Error('id required when a label is given');
    }

    const { valid, invalid, error } = meta;
    const isTextarea = input.type === 'textarea';
    const errorText = customErrorText || error;
    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.

    // Quill does not change touched value when touched so it is not used for now
    const hasError = !!customErrorText || !!(invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    // Textarea doesn't need type.
    const { type, ...inputWithoutType } = input;
    // Uncontrolled input uses defaultValue instead of value.
    const { value: defaultValue, ...inputWithoutValue } = input;
    // Use inputRef if it is passed as prop.
    const refMaybe = inputRef ? { ref: inputRef } : {};

    const quillClassName = quillClass || css.quill;

    const inputClasses =
      inputRootClass ||
      classNames(css.input, {
        [css.inputSuccess]: valid,
        [css.inputError]: hasError,
        [css.textarea]: isTextarea,
      });
    const maxLength = CONTENT_MAX_LENGTH;

    const inputProps = isUncontrolled
      ? {
          className: inputClasses,
          id,
          type,
          defaultValue,
          ...refMaybe,
          ...inputWithoutValue,
          ...rest,
        }
      : { className: inputClasses, id, type, value: input.value, onChange: input.onChange, ...refMaybe, ...input, ...rest };

    const classes = classNames(rootClassName || css.root, className);
    if (typeof window !== 'undefined') {
      return (
        <div className={classes}>
          {label ? <label htmlFor={id}>{label}</label> : null}
          <input type="hidden" {...inputProps} style={{ display: 'none' }} />
          <ReactQuill
            className={quillClassName}
            value={isUncontrolled ? undefined : inputProps.value}
            defaultValue={isUncontrolled ? inputProps.defaultValue : undefined}
            onChange={inputProps.onChange}
            modules={modules}
            formats={['bold', 'italic', 'underline', 'strike', 'blockquote', 'list']}
          />
          <ValidationError fieldMeta={fieldMeta} className={css.errorMessage} />
        </div>
      );
    } else {
      return (
        <div className={classes}>
          {label ? <label htmlFor={id}>{label}</label> : null}
          <input type="hidden" {...inputProps} style={{ display: 'none' }} />
          <textarea />
          <ValidationError fieldMeta={fieldMeta} />
        </div>
      );
    }
  }
}

FieldTextEditorComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
};

FieldTextEditorComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
    type: string,
  }).isRequired,
  meta: object.isRequired,
};

class FieldTextEditor extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldTextEditorComponent} {...this.props} />;
  }
}

export default FieldTextEditor;
