import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  Button,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  KeywordSearchBar,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import {
  NotificationFeed,
  ApplicationNotificationFeed,
  ModalKeywordSearch,
} from '../../components';
import SearchIcon from '../Topbar/SearchIcon';

import config from '../../config';

import css from './TopbarDesktop.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    currentUserNotificationTxs,
    currentUserApplicationTxs,
    rootClassName,
    notificationCount,
    applicationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    history,
    notifications,
    applications,
    urlParam,
    handleKeyWordSubmit,
    onManageDisableScrolling,
    showCategoryBar,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = showCategoryBar
    ? classNames(rootClassName || css.root, className)
    : classNames(rootClassName || css.root, css.boxShadow, className);

  initialSearchFormValues.language = config.locale;

  const accountType =
    currentUser &&
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.account_role;

  const isFreelancer =
    isAuthenticated &&
    currentUser &&
    typeof accountType === 'string' &&
    ['freelancer', 'light-entrepreneur'].includes(accountType);

  const isCustomer =
    isAuthenticated &&
    currentUser &&
    typeof accountType === 'string' &&
    ['customer'].includes(accountType);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      currentUser={currentUser}
      isAuthenticated={isAuthenticated}
      history={history}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  const applicationDot = applicationCount > 0 ? <div className={css.notificationDot} /> : null;

  const renderNotificationFeed = currentUserNotificationTxs.length > 0;
  const renderApplicationFeed = currentUserApplicationTxs.length > 0;

  const inboxLink = authenticatedOnClientSide ? (
    <div className={css.inboxLinkContainer}>
      <NamedLink
        className={css.inboxLink}
        name="InboxPage"
        params={{ tab: isFreelancer ? 'sales' : 'orders' }}
      >
        <span className={css.inbox}>
          <FormattedMessage id="TopbarDesktop.inbox" />
          {notificationDot}
        </span>
      </NamedLink>
      <div className={css.notifications}>
        {renderNotificationFeed && (
          <NotificationFeed
            currentUser={currentUser}
            currentUserNotificationTxs={currentUserNotificationTxs}
            notifications={notifications}
            isFreelancer={isFreelancer}
            intl={intl}
          />
        )}
      </div>
    </div>
  ) : null;

  const applicatonsLink = authenticatedOnClientSide ? (
    <div className={css.inboxLinkContainer}>
      <NamedLink
        className={css.inboxLink}
        name="ApplicationInboxPage"
        // params={{ tab: isFreelancer ? 'sales' : 'orders' }}
      >
        <span className={css.inbox}>
          <FormattedMessage id="TopbarDesktop.applications" />
          {applicationDot}
        </span>
      </NamedLink>
      <div className={css.notifications}>
        {renderApplicationFeed && (
          <ApplicationNotificationFeed
            currentUser={currentUser}
            currentUserApplicationTxs={currentUserApplicationTxs}
            applications={applications}
            isFreelancer={isFreelancer}
            intl={intl}
          />
        )}
      </div>
    </div>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const manageListingsLink = isFreelancer ? (
    <MenuItem key="ManageListingsPage">
      <NamedLink
        className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
        name="ManageListingsPage"
      >
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.yourListingsLink" />
      </NamedLink>
    </MenuItem>
  ) : null;

  const manageProjectsLink = isCustomer ? (
    <MenuItem key="ManageProjectListingsPage">
      <NamedLink
        className={classNames(css.yourListingsLink, currentPageClass('ManageProjectListingsPage'))}
        name="ManageProjectListingsPage"
      >
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.yourProjectsLink" />
      </NamedLink>
    </MenuItem>
  ) : null;

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="DashboardPage">
          <NamedLink
            className={classNames(css.dashboardPageLink, currentPageClass('DashboardPage'))}
            name="DashboardPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.dashboardLink" />
          </NamedLink>
        </MenuItem>
        {manageListingsLink}
        {manageProjectsLink}
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const linkToSellerPage = intl.formatMessage({
    id: 'SectionHowItWorks.linkToSellerPage',
  });

  const linkToReviewsPage = intl.formatMessage({
    id: 'SectionHowItWorks.linkToReviewsPage',
  });

  const forSellerLoggedIn = !isCustomer ? (
    <li className={css.customSelectContainer}>
      <div className={css.customSelectLabel}>
        <NamedLink name="StartSellingInfoPage">{linkToSellerPage}</NamedLink>
      </div>
    </li>
  ) : null;

  const forBuyerLoggedIn = isCustomer ? null : !isAuthenticated ? (
    <>
      <li className={css.customSelectContainer}>
        <div className={css.customSelectLabel}>
          <FormattedMessage id="TopbarDesktop.solutionsDropdown" />
          <i className={css.arrow}></i>
        </div>
        <div className={css.customSelectTwoColums}>
          <ul className={css.customSelectColumn}>
            <div className={css.customSelectColumnLabel}>
              <FormattedMessage id="TopbarDesktop.solutionsDropdownLabelToWho" />
            </div>
            <li className={css.customOption}>
              <NamedLink className={css.customOptionLink} name="InHouseSolutionPage">
                <FormattedMessage id="TopbarDesktop.solutionsInHousePage" />
                <div className={css.customOptionDescription}>
                  <FormattedMessage id="TopbarDesktop.solutionsInHousePageDescription" />
                </div>
              </NamedLink>
            </li>
            <li className={css.customOption}>
              <NamedLink className={css.customOptionLink} name="OrganizationSolutionPage">
                <FormattedMessage id="TopbarDesktop.solutionsOrganizationPage" />
                <div className={css.customOptionDescription}>
                  <FormattedMessage id="TopbarDesktop.solutionsOrganizationPageDescription" />
                </div>
              </NamedLink>
            </li>
            <li className={css.customOption}>
              <NamedLink className={css.customOptionLink} name="StartupSolutionPage">
                <FormattedMessage id="TopbarDesktop.solutionsStartupPage" />
                <div className={css.customOptionDescription}>
                  <FormattedMessage id="TopbarDesktop.solutionsStartupPageDescription" />
                </div>
              </NamedLink>
            </li>
          </ul>
          <ul className={css.customSelectColumn}>
            <div className={css.customSelectColumnLabel}>
              <FormattedMessage id="TopbarDesktop.solutionsDropdownLabelExpertise" />
            </div>
            <li className={css.customOption}>
              <NamedLink className={css.customOptionLink} name="MarketingSolutionPage">
                <FormattedMessage id="TopbarDesktop.solutionsMarketingPage" />
                <div className={css.customOptionDescription}>
                  <FormattedMessage id="TopbarDesktop.solutionsMarketingPageDescription" />
                </div>
              </NamedLink>
            </li>
            <li className={css.customOption}>
              <NamedLink className={css.customOptionLink} name="CommunicationSolutionPage">
                <FormattedMessage id="TopbarDesktop.solutionsCommunicationPage" />
                <div className={css.customOptionDescription}>
                  <FormattedMessage id="TopbarDesktop.solutionsCommunicationPageDescription" />
                </div>
              </NamedLink>
            </li>
            <li className={css.customOption}>
              <NamedLink className={css.customOptionLink} name="CreativeContentSolutionPage">
                <FormattedMessage id="TopbarDesktop.solutionsCreativePage" />
                <div className={css.customOptionDescription}>
                  <FormattedMessage id="TopbarDesktop.solutionsCreativePageDescription" />
                </div>
              </NamedLink>
            </li>
          </ul>
        </div>
      </li>
    </>
  ) : null;

  const resourcesDropdown = (
    <li className={css.customSelectContainer}>
      <div className={css.customSelectLabel}>
        <FormattedMessage id="TopBarMobileMenu.resources" />
        <i className={css.arrow}></i>
      </div>
      <ul className={css.customSelect}>
        <li className={css.customOption}>
          <NamedLink className={css.customOptionLink} name="StartBuyingInfoPage">
            <FormattedMessage id="TopbarDesktop.startBuyingPage" />
            <div className={css.customOptionDescription}>
              <FormattedMessage id="TopbarDesktop.startBuyingPageDescription" />
            </div>
          </NamedLink>
        </li>
        <li className={css.customOption}>
          <NamedLink className={css.customOptionLink} name="SuccessStoriesPage">
            <FormattedMessage id="TopbarDesktop.successStoriesPage" />
            <div className={css.customOptionDescription}>
              <FormattedMessage id="TopbarDesktop.successStoriesDescription" />
            </div>
          </NamedLink>
        </li>
        <li className={css.customOption}>
          <NamedLink className={css.customOptionLink} name="ProLandingPage">
            <FormattedMessage id="TopbarDesktop.proLandingPage" />
            <div className={css.customOptionDescription}>
              <FormattedMessage id="TopbarDesktop.freedomlyProDescription" />
            </div>
          </NamedLink>
        </li>
        <li className={css.customOption}>
          <NamedLink className={css.customOptionLink} name="AboutUsPage">
            <FormattedMessage id="Footer.toAboutPage" />
            <div className={css.customOptionDescription}>
              <FormattedMessage id="TopbarDesktop.aboutUsPageDescription" />
            </div>
          </NamedLink>
        </li>
      </ul>
    </li>
  );

  const addNewJobButton = isAuthenticated ? (
    <NamedLink name="NewProjectListingPage" className={css.addNewButton}>
      <FormattedMessage id="ManageListingCard.createProjectButton" />
    </NamedLink>
  ) : null;

  const addNewButton =
    currentUser && typeof accountType === 'string' && ['customer'].includes(accountType) ? (
      <div>{addNewJobButton}</div>
    ) : null;

  const showSearchBar = ['ParentCategoryPage', 'ChildCategoryPage', 'SearchPage', 'CategoriesPage'];

  const searchBar = showSearchBar.includes(currentPage) ? (
    <div className={css.searchBar}>
      <KeywordSearchBar
        onSubmit={values => {
          handleKeyWordSubmit(values, history);
        }}
        name={urlParam}
        id="TopbarKeywordSearch"
        rootClassName={css.keywordSearchBarRoot}
        submitButtonClassName={css.keyWordsubmitButtonClassName}
      />
    </div>
  ) : null;

  const searchButton = showSearchBar.includes(currentPage) ? (
    <Button
      rootClassName={css.searchModalButton}
      onClick={() => setIsSearchModalOpen(true)}
      title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
    >
      <SearchIcon className={css.searchModalIcon} />
    </Button>
  ) : null;

  return (
    <nav className={classes}>
      <div className={css.container}>
        {currentUser ? (
          <>
            <NamedLink className={css.logoLinkDesktop} name="DashboardPage">
              <Logo
                format="desktop"
                className={css.logo}
                alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
              />
            </NamedLink>
            <NamedLink className={css.logoLinkMobile} name="DashboardPage">
              <Logo
                format="mobile"
                className={css.logo}
                alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
              />
            </NamedLink>
          </>
        ) : (
          <>
            <NamedLink className={css.logoLinkDesktop} name="LandingPage">
              <Logo
                format="desktop"
                className={css.logo}
                alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
              />
            </NamedLink>
            <NamedLink className={css.logoLinkMobile} name="LandingPage">
              <Logo
                format="mobile"
                className={css.logo}
                alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
              />
            </NamedLink>
          </>
        )}
        {forBuyerLoggedIn}
        {resourcesDropdown}
        {forSellerLoggedIn}
        <li className={css.customSelectContainer}>
          <div className={css.customSelectLabel}>
            <NamedLink name="ReviewsPage">{linkToReviewsPage}</NamedLink>
          </div>
        </li>
        {searchBar}
        {searchButton}
        {/* {howItWorksLink} */}
      </div>
      <div className={css.container2}>
        {/* {!isAuthenticated && resourcesDropdown} */}
        {addNewButton}
        {inboxLink}
        {applicatonsLink}
        {search}
        {loginLink}
        {signupLink}

        {profileMenu}
      </div>

      <ModalKeywordSearch
        modalId="TopbarDesktopKeywordSearchModal"
        searchId="TopbarDesktopKeywordSearch"
        isOpen={isSearchModalOpen}
        onClose={() => setIsSearchModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        handleSubmit={handleKeyWordSubmit}
        urlParam={urlParam}
        containerClass={css.searchModal}
      />
    </nav>
  );
};

const { array, bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentUserNotificationTxs: [],
  currentUserApplicationTxs: [],
  currentPage: null,
  notificationCount: 0,
  applicationCount: 0,
  initialSearchFormValues: {},
  notifications: [],
  applications: [],
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserNotificationTxs: array,
  currentUserApplicationTxs: array,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  applicationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  notifications: array,
  applications: array,
};

export default TopbarDesktop;
