export const staticFreelancerPageContents = [
  {
    id: 'freelancer-cro',
    path: '/konversio-optimointi',
    name: 'FreelancerCroPage',
    schemaTitle: 'FreelancerCroPage.schemaTitle',
    schemaDescription: 'FreelancerCroPage.schemaDescription',
    heroTitle: 'FreelancerCroPage.heroTitle',
    heroSubtitle: 'FreelancerCroPage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerCroPage.sectionHowItWorksTitle',
    sectionHowItWorksCardDescription1: 'FreelancerStaticPage.SectionHowItWorksCardDescription1',
    sectionFAQTitle: 'FreelancerCroPage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerCroPage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerCroPage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerCroPage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerCroPage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerCroPage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerCroPage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerCroPage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerCroPage.sectionFAQAnswer4',
    heroImage: 'freelancer-static-hero.png',
  },
  {
    id: 'freelancer-marketing',
    path: '/markkinointi',
    name: 'FreelancerMarketingPage',
    schemaTitle: 'FreelancerMarketingPage.schemaTitle',
    schemaDescription: 'FreelancerMarketingPage.schemaDescription',
    heroTitle: 'FreelancerMarketingPage.heroTitle',
    heroSubtitle: 'FreelancerMarketingPage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerMarketingPage.sectionHowItWorksTitle',
    sectionHowItWorksCardDescription1: 'FreelancerStaticPage.SectionHowItWorksCardDescription1',
    sectionFAQTitle: 'FreelancerMarketingPage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerMarketingPage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerMarketingPage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerMarketingPage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerMarketingPage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerMarketingPage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerMarketingPage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerMarketingPage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerMarketingPage.sectionFAQAnswer4',
    heroImage: 'freelancer-static-hero.png',
  },
  {
    id: 'freelancer-copywriter',
    path: '/copywriter',
    name: 'FreelancerCopywriterPage',
    schemaTitle: 'FreelancerCopywriterPage.schemaTitle',
    schemaDescription: 'FreelancerCopywriterPage.schemaDescription',
    heroTitle: 'FreelancerCopywriterPage.heroTitle',
    heroSubtitle: 'FreelancerCopywriterPage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerCopywriterPage.sectionHowItWorksTitle',
    sectionHowItWorksCardDescription1: 'FreelancerStaticPage.SectionHowItWorksCardDescription1',
    sectionFAQTitle: 'FreelancerCopywriterPage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerCopywriterPage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerCopywriterPage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerCopywriterPage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerCopywriterPage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerCopywriterPage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerCopywriterPage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerCopywriterPage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerCopywriterPage.sectionFAQAnswer4',
    heroImage: 'freelancer-static-hero.png',
  },
  {
    id: 'freelancer-google',
    path: '/google',
    name: 'FreelancerGooglePage',
    schemaTitle: 'FreelancerGooglePage.schemaTitle',
    schemaDescription: 'FreelancerGooglePage.schemaDescription',
    heroTitle: 'FreelancerGooglePage.heroTitle',
    heroSubtitle: 'FreelancerGooglePage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerGooglePage.sectionHowItWorksTitle',
    sectionHowItWorksCardDescription1: 'FreelancerStaticPage.SectionHowItWorksCardDescription1',
    sectionFAQTitle: 'FreelancerGooglePage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerGooglePage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerGooglePage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerGooglePage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerGooglePage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerGooglePage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerGooglePage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerGooglePage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerGooglePage.sectionFAQAnswer4',
    heroImage: 'freelancer-static-hero.png',
  },
  {
    id: 'freelancer-programming',
    path: '/koodarit',
    name: 'FreelancerProgrammingPage',
    schemaTitle: 'FreelancerProgrammingPage.schemaTitle',
    schemaDescription: 'FreelancerProgrammingPage.schemaDescription',
    heroTitle: 'FreelancerProgrammingPage.heroTitle',
    heroSubtitle: 'FreelancerProgrammingPage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerProgrammingPage.sectionHowItWorksTitle',
    sectionHowItWorksCardDescription1: 'FreelancerStaticPage.SectionHowItWorksCardDescription1',
    sectionFAQTitle: 'FreelancerProgrammingPage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerProgrammingPage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerProgrammingPage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerProgrammingPage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerProgrammingPage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerProgrammingPage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerProgrammingPage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerProgrammingPage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerProgrammingPage.sectionFAQAnswer4',
    heroImage: 'freelancer-static-hero.png',
  },
  {
    id: 'freelancer-digital-marketing-expert',
    path: '/digitaalisen-markkinoinnin-asiantuntijat',
    name: 'FreelancerDigitalMarketingExpertPage',
    schemaTitle: 'FreelancerDigitalMarketingExpertPage.schemaTitle',
    schemaDescription: 'FreelancerDigitalMarketingExpertPage.schemaDescription',
    heroTitle: 'FreelancerDigitalMarketingExpertPage.heroTitle',
    heroSubtitle: 'FreelancerDigitalMarketingExpertPage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerStaticPage.sectionHowItWorksTitle',
    sectionHowItWorksCardDescription1:
      'FreelancerDigitalMarketingExpertPage.sectionHowItWorksCardDescription1',
    sectionFAQTitle: 'FreelancerDigitalMarketingExpertPage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerDigitalMarketingExpertPage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerStaticPage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerStaticPage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerDigitalMarketingExpertPage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerDigitalMarketingExpertPage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerStaticPage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerStaticPage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerDigitalMarketingExpertPage.sectionFAQAnswer4',
    heroImage: 'startup-freelancer-hero-image.webp',
  },
  {
    id: 'freelancer-digital-marketing-talent',
    path: '/digitaalisen-markkinoinnin-osaajat',
    name: 'FreelancerDigitalMarketingTalentPage',
    schemaTitle: 'FreelancerDigitalMarketingTalentPage.schemaTitle',
    schemaDescription: 'FreelancerDigitalMarketingTalentPage.schemaDescription',
    heroTitle: 'FreelancerDigitalMarketingTalentPage.heroTitle',
    heroSubtitle: 'FreelancerDigitalMarketingTalentPage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerStaticPage.sectionHowItWorksTitle',
    sectionHowItWorksCardDescription1:
      'FreelancerDigitalMarketingTalentPage.sectionHowItWorksCardDescription1',
    sectionFAQTitle: 'FreelancerDigitalMarketingTalentPage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerDigitalMarketingTalentPage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerStaticPage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerStaticPage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerDigitalMarketingTalentPage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerDigitalMarketingTalentPage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerStaticPage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerStaticPage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerDigitalMarketingTalentPage.sectionFAQAnswer4',
    heroImage: 'startup-freelancer-hero-image.webp',
  },
  {
    id: 'freelancer-seo-talent',
    path: '/seo-osaajat',
    name: 'FreelancerSEOTalentPage',
    schemaTitle: 'FreelancerSEOTalentPage.schemaTitle',
    schemaDescription: 'FreelancerSEOTalentPage.schemaDescription',
    heroTitle: 'FreelancerSEOTalentPage.heroTitle',
    heroSubtitle: 'FreelancerSEOTalentPage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerStaticPage.sectionHowItWorksTitle',
    sectionHowItWorksCardDescription1: 'FreelancerSEOTalentPage.sectionHowItWorksCardDescription1',
    sectionFAQTitle: 'FreelancerSEOTalentPage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerSEOTalentPage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerStaticPage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerStaticPage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerSEOTalentPage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerSEOTalentPage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerStaticPage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerStaticPage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerSEOTalentPage.sectionFAQAnswer4',
    heroImage: 'organization-freelancer-hero-image.webp',
  },
  {
    id: 'freelancer-seo-consultant',
    path: '/seo-konsultit',
    name: 'FreelancerSEOConsultantPage',
    schemaTitle: 'FreelancerSEOConsultantPage.schemaTitle',
    schemaDescription: 'FreelancerSEOConsultantPage.schemaDescription',
    heroTitle: 'FreelancerSEOConsultantPage.heroTitle',
    heroSubtitle: 'FreelancerSEOConsultantPage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerStaticPage.sectionHowItWorksTitle',
    sectionHowItWorksCardDescription1:
      'FreelancerSEOConsultantPage.sectionHowItWorksCardDescription1',
    sectionFAQTitle: 'FreelancerSEOConsultantPage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerSEOConsultantPage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerStaticPage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerStaticPage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerSEOConsultantPage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerSEOConsultantPage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerStaticPage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerStaticPage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerSEOConsultantPage.sectionFAQAnswer4',
    heroImage: 'organization-freelancer-hero-image.webp',
  },
  {
    id: 'freelancer-outsourced-marketing-manager',
    path: '/ulkoistettu-markkinointipäällikkö',
    name: 'FreelancerOutsourcedMarketingManagerPage',
    schemaTitle: 'FreelancerOutsourcedMarketingManagerPage.schemaTitle',
    schemaDescription: 'FreelancerOutsourcedMarketingManagerPage.schemaDescription',
    heroTitle: 'FreelancerOutsourcedMarketingManagerPage.heroTitle',
    heroSubtitle: 'FreelancerOutsourcedMarketingManagerPage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerStaticPage.sectionHowItWorksTitle',
    sectionHowItWorksCardDescription1:
      'FreelancerOutsourcedMarketingManagerPage.sectionHowItWorksCardDescription1',
    sectionFAQTitle: 'FreelancerOutsourcedMarketingManagerPage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerOutsourcedMarketingManagerPage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerStaticPage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerStaticPage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerOutsourcedMarketingManagerPage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerOutsourcedMarketingManagerPage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerStaticPage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerStaticPage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerOutsourcedMarketingManagerPage.sectionFAQAnswer4',
    heroImage: 'marketing-freelancer-hero-image.webp',
  },
  {
    id: 'freelancer-outsourced-communication-manager',
    path: '/ulkoistettu-viestintäpäällikkö',
    name: 'FreelancerOutsourcedCommunicationManagerPage',
    schemaTitle: 'FreelancerOutsourcedCommunicationManagerPage.schemaTitle',
    schemaDescription: 'FreelancerOutsourcedCommunicationManagerPage.schemaDescription',
    heroTitle: 'FreelancerOutsourcedCommunicationManagerPage.heroTitle',
    heroSubtitle: 'FreelancerOutsourcedCommunicationManagerPage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerStaticPage.sectionHowItWorksTitle',
    sectionHowItWorksCardDescription1:
      'FreelancerOutsourcedCommunicationManagerPage.sectionHowItWorksCardDescription1',
    sectionFAQTitle: 'FreelancerOutsourcedCommunicationManagerPage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerOutsourcedCommunicationManagerPage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerStaticPage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerStaticPage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerOutsourcedCommunicationManagerPage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerOutsourcedCommunicationManagerPage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerStaticPage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerStaticPage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerOutsourcedCommunicationManagerPage.sectionFAQAnswer4',
    heroImage: 'marketing-freelancer-hero-image.webp',
  },
];
