import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '..';

import css from './SectionHowItWorks.css';

import lightningIcon from '../../assets/lightning-icon.svg';
import magicHatIcon from '../../assets/magic-hat-icon.svg';
import scaleIcon from '../../assets/scale-icon.svg';
import infoImage from '../../assets/freelancer-image-solutions-2.webp';

import { MdVerified } from 'react-icons/md';
import { BsFillCheckCircleFill } from 'react-icons/bs';

const ContentCard = ({ title, description, icon }) => {
  return (
    <div className={css.contentCard}>
      <div className={css.contentCardIcon}>
        <img src={icon} alt="icon" />
      </div>
      <div className={css.cardTextSection}>
        <div className={css.contentCardTitle}>{title}</div>
        <div className={css.contentCardDescription}>{description}</div>
      </div>
    </div>
  );
};

const SectionHowItWorks = props => {
  const {
    header,
    cardTitle1,
    cardTitle2,
    cardTitle3,
    cardDescription1,
    cardDescription2,
    cardDescription3,
    infoImageName,
    infoImageText1,
    infoImageText2,
    infoImageText3,
    infoImageText4,
    cta,
    atLandingPage,
  } = props;

  return (
    <div className={css.container}>
      <div className={css.title}>{header}</div>
      <div className={css.contentContainer}>
        <div className={css.contentLeft}>
          <ContentCard title={cardTitle1} description={cardDescription1} icon={lightningIcon} />
          <ContentCard title={cardTitle2} description={cardDescription2} icon={magicHatIcon} />
          <ContentCard title={cardTitle3} description={cardDescription3} icon={scaleIcon} />
        </div>
        <div className={css.contentRight}>
          <div className={css.infoSectionContentImageContainer}>
            <div className={css.infoSectionContentImage}>
              <img src={infoImage} alt={infoImageName} />
            </div>
            <div className={css.infoSectionContentImageCard}>
              <div className={css.infoSectionContentImageCardTitle}>
                {infoImageName}
                <MdVerified className={css.infoSectionContentImageCardTitleIcon} />
              </div>
              <div className={css.infoSectionContentImageCardText}>
                <BsFillCheckCircleFill className={css.infoSectionContentImageCardListIcon} />
                {infoImageText1}
              </div>
              <div className={css.infoSectionContentImageCardText}>
                <BsFillCheckCircleFill className={css.infoSectionContentImageCardListIcon} />
                {infoImageText2}
              </div>
              <div className={css.infoSectionContentImageCardText}>
                <BsFillCheckCircleFill className={css.infoSectionContentImageCardListIcon} />
                {infoImageText3}
              </div>
              <div className={css.infoSectionContentImageCardText}>
                <BsFillCheckCircleFill className={css.infoSectionContentImageCardListIcon} />
                {infoImageText4}
              </div>
            </div>
          </div>
        </div>
      </div>
      {atLandingPage ? (
        <div className={css.subTitle}>
          <NamedLink name="SignupPage">{cta}</NamedLink>
        </div>
      ) : (
        <div className={css.buttonContainer}>
          <NamedLink className={css.heroButton} name="SignupPage">
            {cta}
          </NamedLink>
        </div>
      )}
    </div>
  );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
