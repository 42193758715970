import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { withViewport } from '../../util/contextHelpers';
import classNames from 'classnames';

import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  ExternalLink,
  SectionCustomerLogos,
  SectionHowItWorks,
  SectionLocations,
  FaqDropdown,
  SuccessStoryBanner,
  NamedLink,
} from '../../components';
import { TopbarContainer } from '..';
import { FormattedMessage } from '../../util/reactIntl';
import { BsFillCheckCircleFill } from 'react-icons/bs';

import { IoIosArrowDown } from 'react-icons/io';

import SolutionPageHeroSection from './SolutionPageHeroSection';

import heroImage from '../../assets/in-house-team-freelancer-hero-image.webp';
import succesStoryBannerImage from '../../assets/eerikkila-success-story-quote.webp';
import miikkaImg from '../../assets/pro-miikka-salminen.webp';

import css from './SolutionPages.css';

const InHouseSolutionPageComponent = props => {
  const { intl, scrollingDisabled } = props;

  const schemaTitle = intl.formatMessage({ id: 'InHouseSolutionPage.schemaTitle' });
  const schemaDescription = intl.formatMessage({
    id: 'InHouseSolutionPage.schemaDescription',
  });

  const heroTitle = (
    <FormattedMessage
      id="InHouseSolutionPage.heroTitle"
      values={{
        b: chunks => <strong style={{ color: '#1683A3', fontWeight: 600 }}>{chunks}</strong>,
        lineBreak: <br />,
      }}
    />
  );
  const heroSubtitle = intl.formatMessage({ id: 'InHouseSolutionPage.heroSubtitle' });
  const heroButtonText = intl.formatMessage({ id: 'InHouseSolutionPage.heroButtonText' });

  const handleShowSpaceHome = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
    }
  };

  const HowItWorksCard = ({ title, description, checkmarkText }) => {
    return (
      <div className={css.howItWorksCard}>
        <div>
          <div className={css.howItWorksCardTitle}>{title}</div>
          <div className={css.howItWorksCardDescription}>{description}</div>
        </div>
        <div className={css.howItWorksCardCheckmarkContainer}>
          <BsFillCheckCircleFill className={css.howItWorksCardCheckmark} />
          {checkmarkText}
        </div>
      </div>
    );
  };

  const sectionHowItWorksHeader = (
    <FormattedMessage id="InHouseSolutionPage.sectionHowItWorksHeader" />
  );
  const sectionHowItWorksCardTitle1 = (
    <FormattedMessage id="InHouseSolutionPage.sectionHowItWorksCardTitle1" />
  );
  const sectionHowItWorksCardTitle2 = (
    <FormattedMessage id="InHouseSolutionPage.sectionHowItWorksCardTitle2" />
  );
  const sectionHowItWorksCardTitle3 = (
    <FormattedMessage id="InHouseSolutionPage.sectionHowItWorksCardTitle3" />
  );
  const sectionHowItWorksCardDescription1 = (
    <FormattedMessage id="InHouseSolutionPage.sectionHowItWorksCardDescription1" />
  );
  const sectionHowItWorksCardDescription2 = (
    <FormattedMessage id="InHouseSolutionPage.sectionHowItWorksCardDescription2" />
  );
  const sectionHowItWorksCardDescription3 = (
    <FormattedMessage id="InHouseSolutionPage.sectionHowItWorksCardDescription3" />
  );
  const sectionHowItWorksInfoImageName = (
    <FormattedMessage id="InHouseSolutionPage.sectionHowItWorksInfoImageName" />
  );
  const sectionHowItWorksInfoImageText1 = (
    <FormattedMessage id="InHouseSolutionPage.sectionHowItWorksInfoImageText1" />
  );
  const sectionHowItWorksInfoImageText2 = (
    <FormattedMessage id="InHouseSolutionPage.sectionHowItWorksInfoImageText2" />
  );
  const sectionHowItWorksInfoImageText3 = (
    <FormattedMessage id="InHouseSolutionPage.sectionHowItWorksInfoImageText3" />
  );
  const sectionHowItWorksInfoImageText4 = (
    <FormattedMessage id="InHouseSolutionPage.sectionHowItWorksInfoImageText4" />
  );
  const sectionHowItWorksCTA = <FormattedMessage id="InHouseSolutionPage.sectionHowItWorksCTA" />;

  const howItWorksCardTitle1 = <FormattedMessage id="InHouseSolutionPage.howItWorksCardTitle1" />;
  const howItWorksCardText1 = <FormattedMessage id="InHouseSolutionPage.howItWorksCardText1" />;
  const howItWorksCardCheckmarkText1 = (
    <FormattedMessage id="InHouseSolutionPage.howItWorksCardCheckmarkText1" />
  );
  const howItWorksCardTitle2 = <FormattedMessage id="InHouseSolutionPage.howItWorksCardTitle2" />;
  const howItWorksCardText2 = <FormattedMessage id="InHouseSolutionPage.howItWorksCardText2" />;
  const howItWorksCardCheckmarkText2 = (
    <FormattedMessage id="InHouseSolutionPage.howItWorksCardCheckmarkText2" />
  );
  const howItWorksCardTitle3 = <FormattedMessage id="InHouseSolutionPage.howItWorksCardTitle3" />;
  const howItWorksCardText3 = <FormattedMessage id="InHouseSolutionPage.howItWorksCardText3" />;
  const howItWorksCardCheckmarkText3 = (
    <FormattedMessage id="InHouseSolutionPage.howItWorksCardCheckmarkText3" />
  );

  const [faqIsShown, setFaqIsShown] = useState([
    {
      question: <FormattedMessage id="InHouseSolutionPage.sectionFAQQuestion1" />,
      answer: (
        <FormattedMessage
          id="InHouseSolutionPage.sectionFAQAnswer1"
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 0,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id="InHouseSolutionPage.sectionFAQQuestion2" />,
      answer: (
        <FormattedMessage
          id="InHouseSolutionPage.sectionFAQAnswer2"
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 1,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id="InHouseSolutionPage.sectionFAQQuestion3" />,
      answer: (
        <FormattedMessage
          id="InHouseSolutionPage.sectionFAQAnswer3"
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 2,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id="InHouseSolutionPage.sectionFAQQuestion4" />,
      answer: (
        <FormattedMessage
          id="InHouseSolutionPage.sectionFAQAnswer4"
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 3,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
  ]);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="InHouseSolutionPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.sections}>
            <SolutionPageHeroSection
              title={heroTitle}
              subTitle={heroSubtitle}
              buttonText={heroButtonText}
              imgSrc={heroImage}
              handleShowSpaceHome={handleShowSpaceHome}
            />
            <SectionCustomerLogos
              toShow={['peikko', 'mobal', 'eerikkila', 'corego', 'systam', 'zervant']}
            />
            <SectionHowItWorks
              header={sectionHowItWorksHeader}
              cardTitle1={sectionHowItWorksCardTitle1}
              cardTitle2={sectionHowItWorksCardTitle2}
              cardTitle3={sectionHowItWorksCardTitle3}
              cardDescription1={sectionHowItWorksCardDescription1}
              cardDescription2={sectionHowItWorksCardDescription2}
              cardDescription3={sectionHowItWorksCardDescription3}
              infoImageName={sectionHowItWorksInfoImageName}
              infoImageText1={sectionHowItWorksInfoImageText1}
              infoImageText2={sectionHowItWorksInfoImageText2}
              infoImageText3={sectionHowItWorksInfoImageText3}
              infoImageText4={sectionHowItWorksInfoImageText4}
              cta={sectionHowItWorksCTA}
              atLandingPage={true}
            />
            <SuccessStoryBanner
              imgSrc={succesStoryBannerImage}
              textId={'InHouseSolutionPage.successStoryBannerText'}
              fromId={'InHouseSolutionPage.successStoryBannerFrom'}
              companyId={'InHouseSolutionPage.successStoryBannerCompany'}
              pageName={'SuccessStoryEerikkilaPage'}
              buttonTxtId={'InHouseSolutionPage.successStoryBannerCTAtext'}
            />
            <SectionLocations titleId={'InHouseSolutionPage.locationsTitle'} />
            <div className={css.sectionFAQContent}>
              <div className={css.sectionFAQTitle}>
                <FormattedMessage id="InHouseSolutionPage.sectionFAQTitle" />
              </div>
              <FaqDropdown faqIsShown={faqIsShown} setFaqIsShown={setFaqIsShown} />
            </div>
            <div className={css.sectionHowItWorksContent}>
              <div className={css.howItWorksTitle}>
                <FormattedMessage id="StartSellingInfoPage.howItWorksTitle" />
              </div>
              <div className={css.howItWorksCardContainer}>
                <HowItWorksCard
                  title={howItWorksCardTitle1}
                  description={howItWorksCardText1}
                  checkmarkText={howItWorksCardCheckmarkText1}
                />
                <HowItWorksCard
                  title={howItWorksCardTitle2}
                  description={howItWorksCardText2}
                  checkmarkText={howItWorksCardCheckmarkText2}
                />
                <HowItWorksCard
                  title={howItWorksCardTitle3}
                  description={howItWorksCardText3}
                  checkmarkText={howItWorksCardCheckmarkText3}
                />
              </div>
              <div className={css.howItWorksCTA}>
                <NamedLink className={css.howItWorksCTAButton} name="SignupPage">
                  <FormattedMessage id="OrganizationSolutionPage.howItWorksCTA" />
                </NamedLink>
              </div>
            </div>
          </div>
          <div className={css.contactSection}>
            <div className={css.contactSectionContactCard}>
              <div className={css.contactCard}>
                <div className={css.contactCardAvatar}>
                  <img src={miikkaImg} alt="Contact" />
                </div>
                <div className={css.contactCardName}>Miikka Salminen, CEO</div>
                <div className={css.contactCardInformation}>miikka@freedomly.io</div>
                <div className={css.contactCardInformation}>+358 45 268 2218</div>
                <div>
                  <ExternalLink
                    className={css.contactSectionContactButton}
                    onClick={handleShowSpaceHome}
                  >
                    <FormattedMessage id="InHouseSolutionPage.heroContactButton" />
                  </ExternalLink>
                </div>
              </div>
            </div>
            <div className={css.contactSectionTextContent}>
              <div className={css.contactSectionTitle}>
                <FormattedMessage id="InHouseSolutionPage.contactSectionTitle" />
              </div>
              <div className={css.contactSectionContentText}>
                <FormattedMessage id="InHouseSolutionPage.contactSectionText" />
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { number, shape } = PropTypes;

InHouseSolutionPageComponent.propTypes = {
  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired, // from injectIntl
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

const InHouseSolutionPage = compose(
  withRouter,
  connect(mapStateToProps),
  withViewport,
  injectIntl
)(InHouseSolutionPageComponent);

export default InHouseSolutionPage;
