import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { withViewport } from '../../util/contextHelpers';

import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  ExternalLink,
  NamedLink,
  ProContactSection,
} from '../../components';
import heroImage from '../../assets/eerikkila-success-story-banner.webp';

import quoteImg from '../../assets/eerikkila-success-story-quote.webp';

import { TopbarContainer } from '..';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SuccessStoryEerikkilaPage.css';

export const SuccessStoryEerikkilaPageComponent = props => {
  const { intl, scrollingDisabled, viewport } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const schemaTitle = intl.formatMessage({ id: 'SuccessStoryEerikkila.schemaTitle' });
  const schemaDescription = intl.formatMessage({
    id: 'SuccessStoryEerikkila.schemaDescription',
  });

  const handleShowSpaceHome = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
    }
  };

  const MAX_MOBILE_SCREEN_WIDTH = 962;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="SuccessStoryEerikkilaPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroSection}>
            <div className={css.heroSectionLeft}>
              <div className={css.heroSectionBreadcrumbs}>
                <NamedLink name="SuccessStoriesPage">
                  <FormattedMessage id="SuccessStoryEerikkila.heroBreadcrumbs1" />
                </NamedLink>
                <FormattedMessage id="SuccessStoryEerikkila.heroBreadcrumbs2" />
                <FormattedMessage id="SuccessStoryEerikkila.heroBreadcrumbs3" />
              </div>
              {viewport.width <= MAX_MOBILE_SCREEN_WIDTH ? (
                <div className={css.heroSectionMobile}>
                  <img
                    src={heroImage}
                    alt="Hero"
                    className={css.proInfoSectionContentImage}
                    loading="lazy"
                  />
                </div>
              ) : null}
              <div className={css.heroSectionTitle}>
                <FormattedMessage id="SuccessStoryEerikkila.heroTitle" />
              </div>
              <div className={css.heroSectionText}>
                <FormattedMessage id="SuccessStoryEerikkila.heroText" />
              </div>
              <div className={css.heroSectionTags}>
                <div className={css.heroSectionTag}>
                  <FormattedMessage id="SuccessStoryEerikkila.heroTag1" />
                </div>

                <div className={css.heroSectionTag}>
                  <FormattedMessage id="SuccessStoryEerikkila.heroTag2" />
                </div>
              </div>
            </div>
            {viewport.width > MAX_MOBILE_SCREEN_WIDTH ? (
              <div className={css.heroSectionRight}>
                <img
                  src={heroImage}
                  alt="Hero"
                  className={css.proInfoSectionContentImage}
                  loading="lazy"
                />
              </div>
            ) : null}
          </div>
          <div className={css.sections}>
            <div className={css.contentSection}>
              <div className={css.contentSectionContent}>
                <div>
                  <article>
                    <p className={css.contentSectionText}>
                      <FormattedMessage
                        id="SuccessStoryEerikkila.contentSectionParagraph11"
                        values={{
                          a: chunks => (
                            <a
                              href="https://eerikkila.fi"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: '#1683A3', fontWeight: 600 }}
                            >
                              {chunks}
                            </a>
                          ),
                        }}
                      />
                    </p>
                    <p className={css.contentSectionText}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph12" />
                    </p>
                  </article>
                  <header>
                    <h2 className={css.contentSectionTitle}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionTitle2" />
                    </h2>
                  </header>
                  <article>
                    <p className={css.contentSectionText}>
                      <FormattedMessage
                        id="SuccessStoryEerikkila.contentSectionParagraph21"
                        values={{
                          b: chunks => <strong style={{ fontWeight: 600 }}>{chunks}</strong>,
                        }}
                      />
                    </p>
                    <p className={css.contentSectionText}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph22" />
                    </p>
                  </article>
                  <div className={css.articleBlockWithBGWithImage}>
                    <img
                      src={quoteImg}
                      alt="Laura Sirén"
                      className={css.articleBlockWithBGWithImageImage}
                      loading="lazy"
                    />
                    <div className={css.articleBlockWithBGWithImageContent}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph33Quote" />
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph33Name" />
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph33Company" />
                    </div>
                  </div>
                  <header>
                    <h2 className={css.contentSectionTitle}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionTitle4" />
                    </h2>
                  </header>
                  <article>
                    <p className={css.contentSectionText}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph41" />
                    </p>
                    <p className={css.contentSectionText}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph42" />
                    </p>
                  </article>
                  <div className={css.articleBlockWithBGWithImage}>
                    <img
                      src={quoteImg}
                      alt="Laura Sirén"
                      className={css.articleBlockWithBGWithImageImage}
                      loading="lazy"
                    />
                    <div className={css.articleBlockWithBGWithImageContent}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph53Quote" />
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph33Name" />
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph33Company" />
                    </div>
                  </div>
                  {viewport.width <= MAX_MOBILE_SCREEN_WIDTH ? (
                    <div className={css.contentSectionCTAmobile}>
                      <div className={css.contentSectionCTAtitle}>
                        <FormattedMessage id="SuccessStoryZervant.CTAtitle" />
                      </div>
                      <div className={css.contentSectionCTAtext}>
                        <FormattedMessage id="SuccessStoryZervant.CTAtext" />
                      </div>
                      <div className={css.contentSectionCTAbutton}>
                        <ExternalLink
                          className={css.contentSectionContactButton}
                          onClick={handleShowSpaceHome}
                        >
                          <FormattedMessage id="SuccessStoryZervant.CTAbuttonText" />
                        </ExternalLink>
                      </div>
                    </div>
                  ) : null}
                  <header>
                    <h2 className={css.contentSectionTitle}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionTitle6" />
                    </h2>
                  </header>
                  <article>
                    <p className={css.contentSectionText}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph61" />
                    </p>
                    <p className={css.contentSectionText}>
                      <FormattedMessage
                        id="SuccessStoryEerikkila.contentSectionParagraph62"
                        values={{
                          b: chunks => <strong style={{ fontWeight: 600 }}>{chunks}</strong>,
                        }}
                      />
                    </p>
                    <p className={css.contentSectionText}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph63" />
                    </p>
                  </article>
                  <div className={css.articleBlockWithBGWithImage}>
                    <img
                      src={quoteImg}
                      alt="Laura Sirén"
                      className={css.articleBlockWithBGWithImageImage}
                      loading="lazy"
                    />
                    <div className={css.articleBlockWithBGWithImageContent}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph72Quote" />
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph33Name" />
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph33Company" />
                    </div>
                  </div>
                  <header>
                    <h2 className={css.contentSectionTitle}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionTitle8" />
                    </h2>
                  </header>
                  <article>
                    <p className={css.contentSectionText}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph81" />
                    </p>
                    <p className={css.contentSectionText}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph82" />
                    </p>
                    <p className={css.contentSectionText}>
                      <FormattedMessage
                        id="SuccessStoryEerikkila.contentSectionParagraph83"
                        values={{
                          b: chunks => <strong style={{ fontWeight: 600 }}>{chunks}</strong>,
                        }}
                      />
                    </p>
                  </article>
                  <div className={css.articleBlockWithBGWithImage}>
                    <img
                      src={quoteImg}
                      alt="Laura Sirén"
                      className={css.articleBlockWithBGWithImageImage}
                      loading="lazy"
                    />
                    <div className={css.articleBlockWithBGWithImageContent}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph92Quote" />
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph33Name" />
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph33Company" />
                    </div>
                  </div>
                  <header>
                    <h2 className={css.contentSectionTitle}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionTitle10" />
                    </h2>
                  </header>
                  <article>
                    <p className={css.contentSectionText}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionParagraph101" />
                    </p>
                  </article>
                  <div className={css.articleSectionResults}>
                    <div className={css.articleSectionResultsTitle}>
                      <FormattedMessage id="SuccessStoryEerikkila.contentSectionResultsTitle" />
                    </div>
                    <ul className={css.articleSectionResultsList}>
                      <li className={css.articleSectionResultsListItem}>
                        <FormattedMessage
                          id="SuccessStoryEerikkila.contentSectionResultsListItem1"
                          values={{
                            b: chunks => <strong style={{ fontWeight: 600 }}>{chunks}</strong>,
                          }}
                        />
                      </li>
                      <li className={css.articleSectionResultsListItem}>
                        <FormattedMessage
                          id="SuccessStoryEerikkila.contentSectionResultsListItem2"
                          values={{
                            b: chunks => <strong style={{ fontWeight: 600 }}>{chunks}</strong>,
                          }}
                        />
                      </li>
                      <li className={css.articleSectionResultsListItem}>
                        <FormattedMessage
                          id="SuccessStoryEerikkila.contentSectionResultsListItem3"
                          values={{
                            b: chunks => <strong style={{ fontWeight: 600 }}>{chunks}</strong>,
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {viewport.width > MAX_MOBILE_SCREEN_WIDTH ? (
                <div className={css.contentSectionCTAbar}>
                  <div className={css.contentSectionCTA}>
                    <div className={css.contentSectionCTAtitle}>
                      <FormattedMessage id="SuccessStoryZervant.CTAtitle" />
                    </div>
                    <div className={css.contentSectionCTAtext}>
                      <FormattedMessage id="SuccessStoryZervant.CTAtext" />
                    </div>
                    <div className={css.contentSectionCTAbutton}>
                      <ExternalLink
                        className={css.contentSectionContactButton}
                        onClick={handleShowSpaceHome}
                      >
                        <FormattedMessage id="SuccessStoryZervant.CTAbuttonText" />
                      </ExternalLink>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <ProContactSection viewport={viewport} />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

SuccessStoryEerikkilaPageComponent.defaultProps = {
  scrollingDisabled: false,
};

const { number, shape } = PropTypes;

SuccessStoryEerikkilaPageComponent.propTypes = {
  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired, // from injectIntl
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SuccessStoryEerikkila = compose(
  withRouter,
  connect(mapStateToProps),
  withViewport,
  injectIntl
)(SuccessStoryEerikkilaPageComponent);

export default SuccessStoryEerikkila;
