import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, IconEnquiry } from '../../components';
import { propTypes } from '../../util/types';

import css from './LeaveOffPlatformTestimonialForm.css';

const LeaveOffPlatformTestimonialFormComponent = props => {
  const { currentOffPlatformTestimonial, displayName, currentWorkExperienceForTestimonial } = props;

  const initialAuthorName = currentOffPlatformTestimonial?.authorName || 'Default Author Name';

  return (
    <FinalForm
      {...props}
      initialValues={{
        authorName: initialAuthorName, // Set the default value here
      }}
      mutators={{ setFieldData }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          submitButtonWrapperClassName,
          formId,
          handleSubmit,
          inProgress,
          intl,
          values,
        } = fieldRenderProps;

        const companyName = currentWorkExperienceForTestimonial?.customerName;
        const task = currentWorkExperienceForTestimonial?.task;

        const leaveTestimonialInstructions1 = (
          <FormattedMessage
            id="LeaveOffPlatformTestimonialForm.leaveTestimonialInstructions1"
            values={{
              displayName: displayName,
              companyName: companyName,
              task: task,
              b: chunks => <strong style={{ fontWeight: 600 }}>{chunks}</strong>,
            }}
          />
        );
        const leaveTestimonialInstructions2 = intl.formatMessage({
          id: 'LeaveOffPlatformTestimonialForm.leaveTestimonialInstructions2',
        });

        const positionLabel = intl.formatMessage({
          id: 'LeaveOffPlatformTestimonialForm.positionLabel',
        });

        const positionPlaceholder = intl.formatMessage({
          id: 'LeaveOffPlatformTestimonialForm.positionPlaceholder',
        });

        const authorNameLabel = intl.formatMessage({
          id: 'LeaveOffPlatformTestimonialForm.authorNameLabel',
        });

        const authorNamePlaceholder = intl.formatMessage({
          id: 'LeaveOffPlatformTestimonialForm.authorNamePlaceholder',
        });

        const contentLabel = intl.formatMessage({
          id: 'LeaveOffPlatformTestimonialForm.contentLabel',
        });

        const contentPlaceholder = intl.formatMessage({
          id: 'LeaveOffPlatformTestimonialForm.contentPlaceholder',
        });

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <IconEnquiry className={css.icon} />

            <div>
              <article className={css.instructions}>
                <p>{leaveTestimonialInstructions1}</p>
                <p>{leaveTestimonialInstructions2}</p>
              </article>

              <FieldTextInput
                className={css.field}
                type="textarea"
                name="authorName"
                id={formId ? `${formId}.authorName` : 'authorName'}
                label={authorNameLabel}
                placeholder={authorNamePlaceholder}
              />
              <FieldTextInput
                className={css.field}
                type="textarea"
                name="authorPosition"
                id={formId ? `${formId}.authorPosition` : 'authorPosition'}
                label={positionLabel}
                placeholder={positionPlaceholder}
              />
              <FieldTextInput
                className={css.fieldContent}
                type="textarea"
                name="content"
                id={formId ? `${formId}.content` : 'content'}
                label={contentLabel}
                placeholder={contentPlaceholder}
              />
              <div className={css.submitButtonContainer}>
                <PrimaryButton
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled || !values.authorPosition || !values.content}
                >
                  <FormattedMessage id="LeaveOffPlatformTestimonialForm.submitButtonText" />
                </PrimaryButton>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

LeaveOffPlatformTestimonialFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

LeaveOffPlatformTestimonialFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const LeaveOffPlatformTestimonialForm = compose(injectIntl)(
  LeaveOffPlatformTestimonialFormComponent
);

LeaveOffPlatformTestimonialForm.displayName = 'LeaveOffPlatformTestimonialForm';

export default LeaveOffPlatformTestimonialForm;
