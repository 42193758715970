import React from 'react';

import config from '../../config';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';

import {
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Page,
  TalentCard,
  SectionCustomerLogos,
  Button,
  NamedLink,
} from '../../components';
import { TopbarContainer } from '..';
import OptionBanner from './OptionBanner';

import { FaCheckCircle } from 'react-icons/fa';

import { setProjectCategory } from './ProjectCategoryLandingPage.duck.js';
import { setSignUpFrom } from '../SignUpProjectPage/SignUpProjectPage.duck.js';

import testimonialSectionImage from '../../assets/freelanceri-at-cafe.webp';
import facebookImage from '../../assets/freedomlySocialShare.png';
import twitterImage from '../../assets/freedomlySocialShare.png';

import css from './ProjectCategoryLangingPage.css';

const ProjectCategoryLandingPageComponent = props => {
  const {
    content,
    intl,
    history,
    scrollingDisabled,
    onSetProjectCategory,
    onSetSignUpFrom,
  } = props;

  const schemaTitle = intl.formatMessage({ id: content.schemaTitle });
  const schemaDescription = intl.formatMessage({ id: content.schemaDescription });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const heroSectionTalentArray = [
    {
      imageUrl: content.heroCard1Image,
      alt: 'Talent',
      name: <FormattedMessage id={content.heroCardName1} />,
      category: <FormattedMessage id={content.heroCard1} />,
    },
    {
      imageUrl: content.heroCard2Image,
      alt: 'Talent',
      name: <FormattedMessage id={content.heroCardName2} />,
      category: <FormattedMessage id={content.heroCard2} />,
    },
    {
      imageUrl: content.heroCard3Image,
      alt: 'Talent',
      name: <FormattedMessage id={content.heroCardName3} />,
      category: <FormattedMessage id={content.heroCard3} />,
    },
  ];

  const TalentCards = ({ heroSectionTalentArray }) => {
    return (
      <div className={css.talentCards}>
        <TalentCard talent={heroSectionTalentArray[0]} />
        <TalentCard talent={heroSectionTalentArray[1]} />
        <TalentCard talent={heroSectionTalentArray[2]} />
      </div>
    );
  };

  const handleStartProjectClick = () => {
    onSetProjectCategory(content.id, content.formContent);
    onSetSignUpFrom(content.path);
    history.push('/tyoilmoitus');
  };

  const optionBannerFooter = (
    <div className={css.optionBannerFooter}>
      <div className={css.optionBannerFooterButton} onClick={handleStartProjectClick}>
        <FormattedMessage id={content.howItWorksCTAButton} />
      </div>
    </div>
  );

  const productLogos = content?.showProductLogoSection
    ? content.productLogos.map((logo, index) => (
        <div className={css.productLogo} key={index}>
          <img src={logo} alt="Logo" />
        </div>
      ))
    : null;

  const productLogoSection = content?.showProductLogoSection ? (
    <div className={css.productLogosContainer}>
      <div className={css.productLogos}>
        <div className={css.productLogosText}>
          <FormattedMessage id={content.productLogoText} />
        </div>
        {productLogos}
      </div>

      <div className={css.productLogosDivider}></div>
    </div>
  ) : null;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[
        { url: `${config.canonicalRootURL}${facebookImage}`, width: 1200, height: 630 },
      ]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="ProjectCategoryLandingPage"
            // projectCategoryTopBar={true}
            handleStartProjectClick={handleStartProjectClick}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroSection}>
            <div className={css.heroSectionTitle}>
              <div className={css.heroTitle}>
                <FormattedMessage id={content.heroTitle} />
              </div>
              <div className={css.heroSubtitle}>
                <FormattedMessage id={content.heroSubtitle} />
              </div>
            </div>
            <TalentCards heroSectionTalentArray={heroSectionTalentArray} />
            <div className={css.heroSectionContactButtonContainer}>
              <div className={css.heroSectionContactButton} onClick={handleStartProjectClick}>
                <FormattedMessage id={content.heroCTAButton} />
              </div>
            </div>
          </div>
          <SectionCustomerLogos
            toShow={[
              'peikko',
              'olefit',
              'eerikkila',
              'corego',
              'mobal',
              'strongest',
              'systam',
              'zervant',
            ]}
          />
          <div className={css.sections}>
            <div className={css.infoSection}>
              <div className={css.infoSectionTitle}>{content.whatWeOfferSectionTitle}</div>
              <div className={css.infoSectionContent}>
                <div className={css.infoSectionContentImageContainer}>
                  <img
                    src={content.whatWeOfferSectionImage}
                    alt="Info Section"
                    className={css.infoSectionContentImage}
                    loading="lazy"
                  />
                </div>
                <div className={css.infoSectionContentText}>
                  <div className={css.infoSectionContentTextTitle}>
                    <FormattedMessage id={content.whatWeOfferSectionText} />
                  </div>
                  <div className={css.infoSectionContentListTitle}>
                    <FormattedMessage id={content.whatWeOfferListTitle} />
                  </div>
                  <ul className={css.infoSectionList}>
                    <li className={css.infoSectionListItem}>
                      <FaCheckCircle className={css.infoSectionIcon} />
                      <FormattedMessage id={content.whatWeOfferListItem1} />
                    </li>
                    <li className={css.infoSectionListItem}>
                      <FaCheckCircle className={css.infoSectionIcon} />
                      <FormattedMessage id={content.whatWeOfferListItem2} />
                    </li>
                    <li className={css.infoSectionListItem}>
                      <FaCheckCircle className={css.infoSectionIcon} />
                      <FormattedMessage id={content.whatWeOfferListItem3} />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <OptionBanner
              rootClass={css.optionBannerRoot}
              title={content.howItWorksSectionTitle}
              titleDescription={content.howItWorksSectionText}
              step1TitleId={content.howItWorksStepTitle1}
              step2TitleId={content.howItWorksStepTitle2}
              step3TitleId={content.howItWorksStepTitle3}
              step1DescriptionId={content.howItWorksStepText1}
              step2DescriptionId={content.howItWorksStepText2}
              step3DescriptionId={content.howItWorksStepText3}
              footerElement={optionBannerFooter}
            />
            {productLogoSection}
            <div className={css.testimonialSection}>
              <div className={css.testimonialSectionTitle}>
                <FormattedMessage id={content.testimonialsSectionTitle} />
              </div>
              <div className={css.testimonialSectionContent}>
                <div className={css.testimonialSectionContentText}>
                  <div className={css.testimonialSectionListItem}>
                    <div className={css.testimonialSectionListItemText}>
                      <FormattedMessage id="ProLandingPage.proTestimonialSectionText11" />
                      &nbsp;
                      <FormattedMessage id="ProLandingPage.proTestimonialSectionText12" />
                      &nbsp;
                      <FormattedMessage id="ProLandingPage.proTestimonialSectionText13" />
                    </div>
                    <div className={css.testimonialSectionListItemUser}>
                      <FormattedMessage id="ProLandingPage.proTestimonialSectionUser1" />
                    </div>
                  </div>
                  <div className={css.testimonialSectionListItem}>
                    <div className={css.testimonialSectionListItemText}>
                      <FormattedMessage id="WebsiteProjectLandingPage.testimonialSectionText2" />
                    </div>
                    <div className={css.testimonialSectionListItemUser}>
                      <FormattedMessage id="WebsiteProjectLandingPage.testimonialSectionUser2" />
                    </div>
                  </div>
                </div>
                <div className={css.testimonialSectionImageContainer}>
                  <img
                    src={testimonialSectionImage}
                    alt="Testimonial Section"
                    className={css.testimonialSectionContentImage}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <div className={css.contactSection}>
            <div className={css.contactSectionContainer}>
              <div className={css.contactSectionContent}>
                <div className={css.contactSectionTextContent}>
                  <div className={css.contactSectionTitle}>
                    <FormattedMessage id={content.footerSectionTitle} />
                  </div>
                  <div className={css.contactSectionContentText}>
                    <FormattedMessage id={content.footerSectionText} />
                  </div>
                </div>
                <div>
                  <Button className={css.contactSectionButton} onClick={handleStartProjectClick}>
                    <FormattedMessage id={content.footerCTAButton} />
                  </Button>
                </div>
              </div>

              <div className={css.contactSectionFooterInfo}>
                <FormattedMessage id={content.footerFreedomly} />
                <div>
                  <NamedLink name="PrivacyPolicyPage">
                    <FormattedMessage id={content.footerPrivacyPolicy} />
                  </NamedLink>
                  &nbsp; &nbsp; &nbsp;
                  <NamedLink name="TermsOfServicePage">
                    <FormattedMessage id={content.footerTOS} />
                  </NamedLink>
                </div>
              </div>
            </div>
          </div>
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onSetProjectCategory: (category, formContent) =>
    dispatch(setProjectCategory(category, formContent)),
  onSetSignUpFrom: signUpFrom => dispatch(setSignUpFrom(signUpFrom)),
});

const ProjectCategoryLandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ProjectCategoryLandingPageComponent);

export default ProjectCategoryLandingPage;
