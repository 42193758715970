import React from 'react';

import css from './SolutionPages.css';

import { Button, NamedLink } from '../../components';

const SolutionPageHeroSection = props => {
  const { title, subTitle, subTitle2, buttonText, imgSrc, handleShowSpaceHome } = props;

  return (
    <div className={css.heroSection}>
      <div className={css.heroContent}>
        <div className={css.heroTitle}>{title}</div>
        <div className={css.heroSubtitle}>{subTitle}</div>
        {subTitle2 && (
          <>
            &nbsp;<div className={css.heroSubtitle}>{subTitle2}</div>
          </>
        )}
        {buttonText && (
          <NamedLink name="SignupPage" className={css.heroButton}>
            {buttonText}
          </NamedLink>
        )}
      </div>
      <div className={css.heroImage}>
        <img src={imgSrc} alt="Hero" />
      </div>
    </div>
  );
};

export default SolutionPageHeroSection;
