import React, { useState } from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Page,
  SectionHowItWorks,
  SectionPortfolioLogos,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  FaqDropdown,
  ExternalLink,
  SectionCustomerLogos,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { IoIosArrowDown } from 'react-icons/io';

import StaticFreelancerPageHeroSection from './StaticFreelancerPageHeroSection';

import SolutionPageCustomerReviews from '../SolutionLandingPages/SolutionPageCustomerReviews';

import facebookImage from '../../assets/freedomlySocialShare.png';
import twitterImage from '../../assets/freedomlySocialShare.png';
import css from './StaticFreelancerPage.css';

import miikkaImg from '../../assets/pro-miikka-salminen.webp';

export const StaticFreelancerPageComponent = props => {
  const { content, history, intl, location, scrollingDisabled } = props;

  const heroImage = content.heroImage ? require(`../../assets/${content.heroImage}`) : null;

  const handleShowSpaceHome = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
    }
  };

  const schemaTitle = intl.formatMessage({ id: content.schemaTitle });
  const schemaDescription = intl.formatMessage({ id: content.schemaDescription });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const heroTitle = (
    <FormattedMessage
      id={content.heroTitle}
      values={{
        lineBreak: <br />,
      }}
    />
  );
  const heroSubtitle = <FormattedMessage id={content.heroSubtitle} />;
  const heroButtonText = <FormattedMessage id="FreelancerStaticPage.heroCTA" />;

  const sectionHowItWorksHeader = <FormattedMessage id={content.sectionHowItWorksTitle} />;
  const sectionHowItWorksCardTitle1 = (
    <FormattedMessage id="FreelancerStaticPage.SectionHowItWorksCardTitle1" />
  );
  const sectionHowItWorksCardTitle2 = (
    <FormattedMessage id="FreelancerStaticPage.SectionHowItWorksCardTitle2" />
  );
  const sectionHowItWorksCardTitle3 = (
    <FormattedMessage id="FreelancerStaticPage.SectionHowItWorksCardTitle3" />
  );
  const sectionHowItWorksCTA = <FormattedMessage id="FreelancerFinlandPage.sectionHowItWorksCTA" />;

  const sectionHowItWorksCardDescription1 = (
    <FormattedMessage id={content.sectionHowItWorksCardDescription1} />
  );
  const sectionHowItWorksCardDescription2 = (
    <FormattedMessage id="FreelancerStaticPage.SectionHowItWorksCardDescription2" />
  );
  const sectionHowItWorksCardDescription3 = (
    <FormattedMessage id="FreelancerStaticPage.SectionHowItWorksCardDescription3" />
  );

  const sectionHowItWorksInfoImageName = (
    <FormattedMessage id="FreelancerFinlandPage.infoImageName" />
  );
  const sectionHowItWorksInfoImageText1 = (
    <FormattedMessage id="FreelancerFinlandPage.infoImageText1" />
  );
  const sectionHowItWorksInfoImageText2 = (
    <FormattedMessage id="FreelancerFinlandPage.infoImageText2" />
  );
  const sectionHowItWorksInfoImageText3 = (
    <FormattedMessage id="FreelancerFinlandPage.infoImageText3" />
  );
  const sectionHowItWorksInfoImageText4 = (
    <FormattedMessage id="FreelancerFinlandPage.infoImageText4" />
  );

  const [faqIsShown, setFaqIsShown] = useState([
    {
      question: <FormattedMessage id={content.sectionFAQQuestion1} />,
      answer: (
        <FormattedMessage
          id={content.sectionFAQAnswer1}
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 0,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id={content.sectionFAQQuestion2} />,
      answer: (
        <FormattedMessage
          id={content.sectionFAQAnswer2}
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 1,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id={content.sectionFAQQuestion3} />,
      answer: (
        <FormattedMessage
          id={content.sectionFAQAnswer3}
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 2,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id={content.sectionFAQQuestion4} />,
      answer: (
        <FormattedMessage
          id={content.sectionFAQAnswer4}
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 3,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
  ]);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[
        { url: `${config.canonicalRootURL}${facebookImage}`, width: 1200, height: 630 },
      ]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="LandingPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.heroContainer}>
                <StaticFreelancerPageHeroSection
                  title={heroTitle}
                  subTitle={heroSubtitle}
                  buttonText={heroButtonText}
                  imgSrc={heroImage}
                />
              </div>
            </li>
            <li className={css.section}>
              <SectionCustomerLogos
                toShow={[
                  'peikko',
                  'olefit',
                  'eerikkila',
                  'corego',
                  'mobal',
                  'strongest',
                  'systam',
                  'zervant',
                ]}
              />
            </li>
            <li className={css.section}>
              <SectionHowItWorks
                header={sectionHowItWorksHeader}
                cardTitle1={sectionHowItWorksCardTitle1}
                cardTitle2={sectionHowItWorksCardTitle2}
                cardTitle3={sectionHowItWorksCardTitle3}
                cardDescription1={sectionHowItWorksCardDescription1}
                cardDescription2={sectionHowItWorksCardDescription2}
                cardDescription3={sectionHowItWorksCardDescription3}
                infoImageName={sectionHowItWorksInfoImageName}
                infoImageText1={sectionHowItWorksInfoImageText1}
                infoImageText2={sectionHowItWorksInfoImageText2}
                infoImageText3={sectionHowItWorksInfoImageText3}
                infoImageText4={sectionHowItWorksInfoImageText4}
                cta={sectionHowItWorksCTA}
                atLandingPage={true}
              />
            </li>
            <li className={css.section}>
              <SolutionPageCustomerReviews />
            </li>
            <li className={css.section}>
              <SectionPortfolioLogos />
            </li>
            <li className={css.section}>
              <div className={css.sectionFAQContent}>
                <div className={css.sectionFAQTitle}>
                  <FormattedMessage id={content.sectionFAQTitle} />
                </div>
                <FaqDropdown faqIsShown={faqIsShown} setFaqIsShown={setFaqIsShown} />
              </div>
            </li>
          </ul>
          <div className={css.contactSection}>
            <div className={css.contactSectionContactCard}>
              <div className={css.contactCard}>
                <div className={css.contactCardAvatar}>
                  <img src={miikkaImg} alt="Contact" />
                </div>
                <div className={css.contactCardName}>Miikka Salminen, CEO</div>
                <div className={css.contactCardInformation}>miikka@freedomly.io</div>
                <div className={css.contactCardInformation}>+358 45 268 2218</div>
                <div>
                  <ExternalLink
                    className={css.contactSectionContactButton}
                    onClick={handleShowSpaceHome}
                  >
                    <FormattedMessage id="MarketingSolutionPage.heroContactButton" />
                  </ExternalLink>
                </div>
              </div>
            </div>
            <div className={css.contactSectionTextContent}>
              <div className={css.contactSectionTitle}>
                <FormattedMessage id="MarketingSolutionPage.contactSectionTitle" />
              </div>
              <div className={css.contactSectionContentText}>
                <FormattedMessage id="MarketingSolutionPage.contactSectionText" />
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

StaticFreelancerPageComponent.propTypes = {
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const StaticFreelancerPage = compose(withRouter, injectIntl)(StaticFreelancerPageComponent);

export default StaticFreelancerPage;
