import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router-dom';
import {
  TRANSITION_RELEASE_FUNDS,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_MAKE_PROJECT_BID,
  TRANSITION_MAKE_PROJECT_BID_AFTER_ENQUIRY,
  TRANSITION_ACCEPT_OFFER,
  TRANSITION_DECLINE_OFFER,
  TRANSITION_EXPIRE_OFFER,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PROJECT,
  TRANSITION_JOB_DONE,
  TRANSITION_JOB_DONE_RELEASE_FUNDS,
  TRANSITION_JOB_DONE_CUSTOMER,
  TRANSITION_MAKE_CUSTOM_PRO_OFFER,
  TRANSITION_ACCEPT_CUSTOM_PRO_OFFER,
  TRANSITION_JOB_DONE_PRO,
  TRANSITION_JOB_ACCEPTED,
  TRANSITION_SKIP_TO_REVIEWS,
  txIsReviewed,
  txIsInFirstReviewBy,
  txRoleIsCustomer,
  txIsDelivered,
  txLastTransition,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
  txIsProjectConfirmed,
  txIsProReadyAccepted,
  txIsProAccepted,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
  Modal,
  Button,
  PrimaryButton,
  SecondaryButton,
  NeedHelpInfo,
  NamedRedirect,
  ExternalLink,
} from '../../components';
import {
  SendMessageForm,
  CustomOfferForm,
  ProjectBidForm,
  EnquiryForm,
  CustomOfferAcceptedForm,
} from '../../forms';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createSlug } from '../../util/urlHelpers';

// These are internal components that make this file more readable.
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardImage from './DetailCardImage';
import ProjectDetailCardInfo from './ProjectDetailCardInfo';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import ProjectActionButtonsMaybe from './ProjectActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
  HEADING_PROJECT_CONFIRMED,
  HEADING_OFFER_MADE,
  HEADING_OFFER_ACCEPTED,
  HEADING_OFFER_DECLINED,
  HEADING_JOB_DONE,
  HEADING_JOB_DONE_CUSTOMER,
  HEADING_JOB_ACCEPTED,
} from './PanelHeading';

import css from './TransactionPanel.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { TransactionProcessTimeline } from './TransactionProcessTimeline';

const { Money } = sdkTypes;

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

const shouldOpenReviewModal = (transactionRole, transaction) => {
  const isCustomer = txRoleIsCustomer(transactionRole);
  const currentTransition = txLastTransition(transaction);

  switch (currentTransition) {
    case TRANSITION_RELEASE_FUNDS:
    case TRANSITION_JOB_DONE_RELEASE_FUNDS:
      return txIsDelivered(transaction);
    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
      const reviewPeriodIsOver = txIsReviewed(transaction);
      const userHasLeftAReview = txIsInFirstReviewBy(transaction, isCustomer);
      return !(reviewPeriodIsOver || userHasLeftAReview);
    case TRANSITION_SKIP_TO_REVIEWS:
      return txIsProReadyAccepted(transaction);
    case TRANSITION_JOB_ACCEPTED:
      return txIsProReadyAccepted(transaction);
    default:
      return false;
  }
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    const { projectBidModalOpenForTransactionId, transactionRole, transaction } = props;

    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: shouldOpenReviewModal(transactionRole, transaction),
      reviewSubmitted: false,
      projectBidModalOpen: projectBidModalOpenForTransactionId === props.transaction.id,
      newEnquiryModalOpen: false,
      newProjectEnquiryModalOpen: false,
      customOfferModalOpen: false,
      acceptCustomOfferModalOpen: false,
      taxInformationModalOpen: false,
      txInfoOpen: true,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.onAttachmentSubmit = this.onAttachmentSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.handleSendCustomOffer = this.handleSendCustomOffer.bind(this);
    this.handleAcceptCustomOffer = this.handleAcceptCustomOffer.bind(this);
    this.onProjectBid = this.onProjectBid.bind(this);
    this.onSubmitProjectBid = this.onSubmitProjectBid.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.Intercom('update', { hide_default_launcher: true });
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.Intercom('update', { hide_default_launcher: false });
    }
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  onAttachmentSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    }
  }

  handleSendCustomOffer(values, form) {
    const { description, customOfferAmount, deliveryDate } = values;
    const { onSendCustomOffer, transaction } = this.props;
    const isProCustomer = transaction.customer.attributes.profile.publicData.proCustomer;
    const isInvoiceCustomer =
      transaction.customer.attributes?.profile?.publicData?.invoiceEnabled === true;

    const isProProcess = isProCustomer || isInvoiceCustomer;
    onSendCustomOffer(
      this.props.transaction.id.uuid,
      description,
      customOfferAmount,
      this.props.intl,
      deliveryDate,
      isProProcess
    )
      .then(() => {
        this.setState({ customOfferModalOpen: false });
        form.restart();
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  handleAcceptCustomOffer() {
    const currentTransaction = ensureTransaction(this.props.transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const isProCustomer = currentTransaction.customer.attributes.profile.publicData?.proCustomer;
    const isInvoiceCustomer =
      currentTransaction.customer.attributes?.profile?.publicData?.invoiceEnabled === true;

    const isProProcess = isProCustomer || isInvoiceCustomer;

    if (typeof window === 'object') {
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          items: [
            {
              item_name: currentListing.attributes.title,
              item_id: currentListing.id.uuid,
              price: '' + this.props.transaction.attributes.protectedData.customOfferAmount / 100,
              item_brand: currentProvider.attributes.profile.displayName,
              item_category: currentListing.attributes.publicData.category,
              item_variant: 'custom_offer',
              item_list_name: 'chat_window',
              custom_offer: 'true',
              custom_offer_value:
                '' + this.props.transaction.attributes.protectedData.customOfferAmount / 100,
              quantity: '1',
            },
          ],
        },
      });
    }

    this.props
      .onSubmitBookingRequest({
        pricingPackage: 'package1',
        suggestedPrice: new Money(
          this.props.transaction.attributes.protectedData.customOfferAmount,
          'EUR'
        ),
        isCustomOffer: true,
        skipAddToCartEvent: true,
        isProCustomer: isProProcess,
      })
      .then(() => {
        this.setState({ acceptCustomOfferModalOpen: false });
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  onProjectBid() {
    const {
      currentUser,
      history,
      callSetInitialValues,
      setInitialValues,
      location,
      transaction,
    } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, {
        projectBidModalOpenForTransactionId: transaction.id,
      });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ projectBidModalOpen: true }, () => {
        ReactTooltip.rebuild();
      });
    }
  }

  onSubmitProjectBid(values) {
    const currentTransaction = ensureTransaction(this.props.transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const { history, onSendProjectBid } = this.props;
    const routes = routeConfiguration();
    const { description, amount, deliveryDate } = values;

    onSendProjectBid(
      currentListing.id,
      currentTransaction.id,
      description,
      amount.amount,
      this.props.intl,
      deliveryDate
    )
      .then(txId => {
        this.setState({ projectBidModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onJobReady,
      onJobReadyCustomer,
      onJobReadyPro,
      onAcceptProJob,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      timeSlots,
      fetchTimeSlotsError,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      onReleaseFunds,
      releaseFundsInProgress,
      releaseFundsError,
      sendCustomOfferInProgress,
      sendCustomOfferError,
      onDeclineProjectBid,
      declineProjectBidInProgress,
      declineProjectBidError,
      sendProjectBidInProgress,
      sendEnquiryInProgress,
      sendProjectBidError,
      onSubmitAcceptBidRequest,
      showProfileListingInProgress,
      showProfileListingError,
      projectTransaction,
      onSubmitEnquiryCustomer,
      onSubmitNewProjectEnquiry,
      sendEnquiryErrorListing,
      onJobDoneReleaseFunds,
      jobDoneReleaseFundsInProgress,
      jobDoneReleaseFundsError,
      history,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const projectBidDescription =
      currentTransaction?.attributes?.protectedData?.customOfferDescription;

    //For the process change. Old process versions are not supported anymore. This is a temporary fix.
    //Remove this when there is no transactions with old process versions.
    const isStaging =
      process.env.REACT_APP_FINNISH_ROOT_URL === 'https://freedomly-staging.freedomly.io';
    const defaultProcessVersion = isStaging ? 70 : 26;
    const lightProcessVersion = isStaging ? 31 : 14;
    const projectProcessVersion = isStaging ? 20 : 8;
    const oldDefaultProcess =
      currentTransaction.attributes.processName === 'flex-default-process' &&
      currentTransaction.attributes.processVersion < defaultProcessVersion;
    const oldLightProcess =
      currentTransaction.attributes.processName === 'flex-light-process' &&
      currentTransaction.attributes.processVersion < lightProcessVersion;
    const oldProjectProcess =
      currentTransaction.attributes.processName === 'flex-project-process' &&
      currentTransaction.attributes.processVersion <= projectProcessVersion;

    const processName = currentTransaction.attributes.processName;

    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);

    const currentCustomer = ensureUser(currentTransaction.customer);
    const isProCustomer = currentCustomer.attributes?.profile?.publicData?.proCustomer;

    const isInvoiceCustomer =
      currentCustomer.attributes?.profile?.publicData?.invoiceEnabled === true;
    const isProOrInvoiceCustomer = isProCustomer || isInvoiceCustomer;
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';
    const isCustomOfferMade =
      currentTransaction.attributes.protectedData &&
      currentTransaction.attributes.protectedData?.customOfferAmount;

    const currentOfferAmount =
      currentTransaction.attributes.protectedData?.customOfferAmount !== undefined
        ? new Money(currentTransaction.attributes.protectedData?.customOfferAmount, 'EUR')
        : new Money(0, 'EUR');

    const customOfferVATAmount =
      currentTransaction.attributes.protectedData?.customOfferAmount !== undefined
        ? new Money(currentTransaction.attributes.protectedData?.customOfferAmount * 0.255, 'EUR')
        : new Money(0, 'EUR');

    const customOfferPlusVATAmount =
      currentTransaction.attributes.protectedData?.customOfferAmount !== undefined
        ? new Money(currentTransaction.attributes.protectedData?.customOfferAmount * 1.255, 'EUR')
        : new Money(0, 'EUR');

    const customOfferDescription =
      currentTransaction.attributes.protectedData?.customOfferDescription || '';

    const customOfferDeliveryDate =
      currentTransaction.attributes.protectedData?.customOfferDeliveryDate;

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const { publicData = {} } = currentListing.attributes;
    const { listingType = '' } = publicData;

    const isFreelancer =
      currentUser &&
      ['freelancer', 'light-entrepreneur'].includes(
        currentUser.attributes.profile.publicData.account_role
      );

    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 &&
          (transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY) ||
            transitions.includes('transition/accept-custom-pro-offer'));
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: !isProviderBanned && hasCorrectNextTransition,
          showProjectBookingPanel: !isProviderBanned && listingType === 'project',
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if ([TRANSITION_MAKE_CUSTOM_PRO_OFFER].includes(txLastTransition(tx))) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (
        [
          TRANSITION_CONFIRM_PAYMENT,
          TRANSITION_CONFIRM_PROJECT,
          TRANSITION_ACCEPT_CUSTOM_PRO_OFFER,
        ].includes(txLastTransition(tx))
      ) {
        return {
          headingState: HEADING_ACCEPTED,
          showJobReadyButton: isProvider && !isCustomerBanned,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showJobDoneReleaseFundsButton:
            isCustomer && !oldDefaultProcess && !oldLightProcess && !isProOrInvoiceCustomer,
          showJobDoneCustomerButton: isCustomer && (oldDefaultProcess || oldLightProcess),
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsProjectConfirmed(tx)) {
        return {
          headingState: HEADING_PROJECT_CONFIRMED,
          showDetailCardHeadings: isCustomer,
          showReleaseFundsButton: isCustomer && !isProOrInvoiceCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (
        [TRANSITION_MAKE_PROJECT_BID, TRANSITION_MAKE_PROJECT_BID_AFTER_ENQUIRY].includes(
          txLastTransition(tx)
        )
      ) {
        return {
          headingState: HEADING_OFFER_MADE,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          showProjectSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if ([TRANSITION_ACCEPT_OFFER].includes(txLastTransition(tx))) {
        return {
          headingState: HEADING_OFFER_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (
        [TRANSITION_DECLINE_OFFER, TRANSITION_EXPIRE_OFFER].includes(txLastTransition(tx))
      ) {
        return {
          headingState: HEADING_OFFER_DECLINED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if ([TRANSITION_JOB_DONE, TRANSITION_JOB_DONE_PRO].includes(txLastTransition(tx))) {
        return {
          headingState: HEADING_JOB_DONE,
          showReleaseFundsButton: isCustomer && !isProOrInvoiceCustomer,
          showAcceptProButton: isCustomer && isProOrInvoiceCustomer,
          showCustomOfferFormAccepted: isProOrInvoiceCustomer,
        };
      } else if ([TRANSITION_JOB_DONE_CUSTOMER].includes(txLastTransition(tx))) {
        return {
          headingState: HEADING_JOB_DONE_CUSTOMER,
          showReleaseFundsButton: isCustomer && !isProOrInvoiceCustomer,
        };
      } else if ([TRANSITION_JOB_ACCEPTED].includes(txLastTransition(tx))) {
        return {
          headingState: HEADING_JOB_ACCEPTED,
          showReleaseFundsButton: isCustomer && !isProOrInvoiceCustomer,
          showCustomOfferFormAccepted: isProOrInvoiceCustomer,
          showCustomOfferFormAcceptedProvider: isProOrInvoiceCustomer,
        };
      } else if (
        [
          TRANSITION_REVIEW_1_BY_PROVIDER,
          TRANSITION_REVIEW_1_BY_CUSTOMER,
          TRANSITION_REVIEW_2_BY_PROVIDER,
          TRANSITION_REVIEW_2_BY_CUSTOMER,
        ].includes(txLastTransition(tx))
      ) {
        return {
          headingState: HEADING_JOB_ACCEPTED,
          showCustomOfferFormAccepted: isProOrInvoiceCustomer,
          showCustomOfferFormAcceptedProvider: isProOrInvoiceCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const showTimelineNotification =
      (isCustomer &&
        currentTransaction?.attributes?.lastTransition === 'transition/make-custom-offer') ||
      currentTransaction?.attributes?.lastTransition === 'transition/accept-custom-pro-offer'
        ? true
        : (isProvider &&
            currentTransaction?.attributes?.lastTransition === 'transition/confirm-payment') ||
          currentTransaction?.attributes?.lastTransition === 'transition/accept-custom-pro-offer'
        ? true
        : isProvider &&
          currentTransaction?.attributes?.lastTransition === 'transition/confirm-project'
        ? true
        : (isCustomer &&
            currentTransaction?.attributes?.lastTransition === 'transition/job-done') ||
          currentTransaction?.attributes?.lastTransition === 'transition/job-done-pro'
        ? true
        : false;

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentTransaction.attributes.protectedData.listingTitle
      ? currentTransaction.attributes.protectedData?.listingTitle
      : '';

    const listingDescription = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.publicData.descriptionText;
    const authorStripeConnected = currentProvider.attributes.profile.publicData?.stripeConnected;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const slug = createSlug(listingTitle);

    const isTransactionFromApplication =
      currentTransaction.attributes?.protectedData?.applicationId !== undefined;

    const customOfferPlaceholder = intl.formatMessage({
      id: 'TransactionPanel.customOfferPlaceholder',
    });

    const customOfferFormAccepted = isProOrInvoiceCustomer ? (
      <CustomOfferAcceptedForm
        formId="customOfferForm"
        rootClassName={css.customOfferForm}
        messagePlaceholder={customOfferPlaceholder}
        isProvider={isProvider}
        customOfferAmount={currentOfferAmount}
        customOfferPlusVATAmount={customOfferPlusVATAmount}
        customOfferVATAmount={customOfferVATAmount}
        customOfferDescription={customOfferDescription}
        customOfferDeliveryDate={customOfferDeliveryDate}
        inProgress={false}
        offerError={null}
        onSubmit={this.handleAcceptCustomOffer}
        isProOrInvoiceCustomer={isProOrInvoiceCustomer}
        showCustomOfferFormAcceptedProvider={stateData.showCustomOfferFormAcceptedProvider}
      />
    ) : null;

    // Contains all action buttons expect ones related to custom offer.
    const saleButtons = (
      <SaleActionButtonsMaybe
        currentTransaction={currentTransaction}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        releaseFundsInProgress={releaseFundsInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        releaseFundsError={releaseFundsError}
        jobDoneReleaseFundsInProgress={jobDoneReleaseFundsInProgress}
        jobDoneReleaseFundsError={jobDoneReleaseFundsError}
        onManageDisableScrolling={onManageDisableScrolling}
        stateData={stateData}
        intl={intl}
        onAcceptSale={() => onAcceptSale(currentTransaction.id)}
        onDeclineSale={() => onDeclineSale(currentTransaction.id)}
        onReleaseFunds={() => onReleaseFunds(currentTransaction.id)}
        onJobDoneReleaseFunds={() => onJobDoneReleaseFunds(currentTransaction.id)}
        onJobReady={() => onJobReady(currentTransaction.id)}
        onJobReadyCustomer={() => onJobReadyCustomer(currentTransaction.id)}
        onJobReadyPro={() => onJobReadyPro(currentTransaction.id)}
        isProOrInvoiceCustomer={isProOrInvoiceCustomer}
        onAcceptProJob={() => onAcceptProJob(currentTransaction.id)}
        customOfferFormAccepted={customOfferFormAccepted}
      />
    );

    const projectActionButtons = (
      <ProjectActionButtonsMaybe
        showButtons={stateData.showProjectSaleButtons}
        acceptInProgress={showProfileListingInProgress}
        declineInProgress={declineProjectBidInProgress}
        acceptSaleError={showProfileListingError}
        declineSaleError={declineProjectBidError}
        onAcceptSale={onSubmitAcceptBidRequest}
        onDeclineSale={() => onDeclineProjectBid(currentTransaction.id)}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const currentListingType = currentListing.attributes.publicData?.listingType;
    const isProjectListingClosed = projectTransaction?.listing.attributes.state === 'closed';
    const isListingClosed = currentListing.attributes.state === 'closed';
    const projectBidButtonText = <FormattedMessage id="TransactionPanel.ctaButtonProjectBid" />;

    const backToApplicationLinkText = intl.formatMessage({
      id: 'TransactionPanel.backToApplicationLink',
    });

    const handleCustomOfferModal = () => {
      const taxInformationCollected =
        currentUser.attributes.profile.privateData?.taxInformationCollected;

      if (taxInformationCollected) {
        this.setState({ customOfferModalOpen: true });
      } else {
        this.setState({ taxInformationModalOpen: true });
      }
    };

    const openCustomOfferModalButton = [
      HEADING_DECLINED,
      HEADING_ENQUIRED,
      HEADING_REQUESTED,
      HEADING_OFFER_MADE,
      HEADING_CANCELED,
      HEADING_OFFER_DECLINED,
    ].includes(stateData.headingState) ? (
      <SecondaryButton
        className={css.openCustomOfferModalButton}
        onClick={() => handleCustomOfferModal()}
      >
        <FormattedMessage id="CustomOfferForm.makeAnOffer" />
      </SecondaryButton>
    ) : null;

    const handleAcceptCustomOfferModalOpen = () => {
      this.setState({ acceptCustomOfferModalOpen: true });
    };

    const handleCustomOffer = isProOrInvoiceCustomer
      ? handleAcceptCustomOfferModalOpen
      : this.handleAcceptCustomOffer;

    const customOfferForm =
      isProvider && txIsEnquired(currentTransaction) ? (
        <div className={css.serviceBidContainer}>
          <div className={css.serviceBidPrice}>
            <h2 className={css.serviceBidTitle}>{currentListing.attributes.title}</h2>
            <div className={css.serviceBidSubTitle}>{bookingSubTitle}</div>
          </div>
          <div className={css.serviceBidPriceMobile}>
            <div className={css.serviceBidAmount}>{price && formatMoney(intl, price)}</div>
            <div className={css.serviceBidPerUnit}>
              <FormattedMessage id={unitTranslationKey} />
            </div>
          </div>
          <PrimaryButton onClick={() => this.setState({ customOfferModalOpen: true })}>
            <FormattedMessage id="CustomOfferForm.makeAnOffer" />
          </PrimaryButton>
        </div>
      ) : isCustomer && txIsEnquired(currentTransaction) && isCustomOfferMade ? (
        <CustomOfferForm
          formId="customOfferForm"
          rootClassName={css.customOfferForm}
          messagePlaceholder={customOfferPlaceholder}
          isProvider={isProvider}
          customOfferAmount={currentOfferAmount}
          customOfferPlusVATAmount={customOfferPlusVATAmount}
          customOfferVATAmount={customOfferVATAmount}
          customOfferDescription={customOfferDescription}
          customOfferDeliveryDate={customOfferDeliveryDate}
          inProgress={false}
          offerError={null}
          onSubmit={handleCustomOffer}
          isProOrInvoiceCustomer={isProOrInvoiceCustomer}
        />
      ) : null;

    const acceptCustomOfferProModal = isCustomOfferMade ? (
      <Modal
        id="TransactionPanel.acceptCustomOfferProModal"
        contentClassName={css.enquiryModalContent}
        isOpen={this.state.acceptCustomOfferModalOpen}
        onClose={() => {
          this.setState({ acceptCustomOfferModalOpen: false });
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <CustomOfferForm
          formId="customOfferForm"
          rootClassName={css.customOfferForm}
          messagePlaceholder={customOfferPlaceholder}
          isProvider={isProvider}
          customOfferAmount={currentOfferAmount}
          customOfferPlusVATAmount={customOfferPlusVATAmount}
          customOfferVATAmount={customOfferVATAmount}
          customOfferDescription={customOfferDescription}
          customOfferDeliveryDate={customOfferDeliveryDate}
          inProgress={false}
          offerError={null}
          onSubmit={this.handleAcceptCustomOffer}
          isProOrInvoiceCustomer={isProOrInvoiceCustomer}
          isModal={true}
        />
      </Modal>
    ) : null;

    const hideTransactionProcessTimeline =
      currentListingType === 'project' && stateData.headingState === 'offer-accepted'
        ? true
        : false;

    const classes = classNames(rootClassName || css.root, className);

    const handleNewEnquiryClick = e => {
      this.setState({ newEnquiryModalOpen: true });
    };

    const handleNewProjectEnquiryClick = e => {
      this.setState({ newProjectEnquiryModalOpen: true });
    };

    const newEnquiry = (
      <Modal
        id="TransactionPanel.newEnquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={this.state.newEnquiryModalOpen}
        onClose={() => {
          this.setState({ newEnquiryModalOpen: false });
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.newEnquirySelectContainer}>
          <div className={css.newEnquiryNB}>
            <FormattedMessage id="TransactionPanel.newEnquiryNB" />
          </div>
          <EnquiryForm
            className={css.enquiryForm}
            submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
            listingTitle={listingTitle}
            authorDisplayName={currentProvider.attributes.profile.displayName}
            showHiringSteps={false}
            sendEnquiryError={sendEnquiryErrorListing}
            onSubmit={onSubmitEnquiryCustomer}
            inProgress={sendEnquiryInProgress}
          />
        </div>
      </Modal>
    );

    const newProjectEnquiry = (
      <Modal
        id="TransactionPanel.newProjectEnquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={this.state.newProjectEnquiryModalOpen}
        onClose={() => {
          this.setState({ newProjectEnquiryModalOpen: false });
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.newEnquirySelectContainer}>
          <div className={css.newEnquiryNB}>
            <FormattedMessage id="TransactionPanel.newEnquiryNB" />
          </div>
          <EnquiryForm
            className={css.enquiryForm}
            submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
            listingTitle={listingTitle}
            authorDisplayName={currentProvider.attributes.profile.displayName}
            showHiringSteps={false}
            sendEnquiryError={sendEnquiryErrorListing}
            onSubmit={onSubmitNewProjectEnquiry}
            inProgress={sendEnquiryInProgress}
          />
        </div>
      </Modal>
    );

    // Old transactions created from project doesn't have listing id in protected data,
    // so render plain title instead.
    const projectListingLink = currentTransaction.attributes.protectedData.listingId ? (
      <div className={css.projectLinks}>
        <NamedLink
          name="ProjectListingPage"
          params={{ id: currentTransaction.attributes.protectedData.listingId, slug }}
        >
          {listingTitle}
        </NamedLink>
        <NamedLink
          className={css.applicationLink}
          name={isCustomer ? 'ApplicationSalePage' : 'ApplicationOrderPage'}
          params={{ id: currentTransaction.attributes.protectedData.applicationId }}
        >
          {backToApplicationLinkText} &rarr;
        </NamedLink>
      </div>
    ) : (
      <div>{listingTitle}</div>
    );

    const listingLink = (
      <NamedLink name="ListingPage" params={{ id: currentListing.id.uuid, slug }}>
        {listingTitle}
      </NamedLink>
    );

    const bookingPanelListingLink = isTransactionFromApplication
      ? { ...projectListingLink }
      : { ...listingLink };

    // Redirect project process to application page if not legacy project
    if (processName === 'flex-project-process' && !oldProjectProcess) {
      return isCustomer ? (
        <NamedRedirect name="ApplicationOrderPage" params={{ id: currentTransaction.id.uuid }} />
      ) : (
        <NamedRedirect name="ApplicationSalePage" params={{ id: currentTransaction.id.uuid }} />
      );
    }

    const handleTabSwitch = txInfoOpen => {
      this.setState({ txInfoOpen });
    };

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.backToInboxLinkMobile}>
            <NamedLink name="InboxPage" params={{ tab: isFreelancer ? 'sales' : 'orders' }}>
              <FormattedMessage id="TransactionPanel.backToInboxLink" />
            </NamedLink>
          </div>
          <div className={css.mobileTabs}>
            <div
              className={this.state.txInfoOpen ? css.mobileTabActive : css.mobileTab}
              onClick={() => handleTabSwitch(true)}
            >
              <FormattedMessage id="TransactionPanel.mobileTabTxInfo" />
            </div>
            <div
              className={this.state.txInfoOpen ? css.mobileTab : css.mobileTabActive}
              onClick={() => handleTabSwitch(false)}
            >
              <FormattedMessage id="TransactionPanel.mobileTabAsideDesktop" />
              {showTimelineNotification ? <div className={css.notificationDot} /> : null}
            </div>
          </div>
          <div className={this.state.txInfoOpen ? css.txInfo : css.txInfoHiddenMobile}>
            <div className={css.backToInboxLink}>
              <NamedLink name="InboxPage" params={{ tab: isFreelancer ? 'sales' : 'orders' }}>
                <FormattedMessage id="TransactionPanel.backToInboxLink" />
              </NamedLink>
            </div>

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              provider={currentProvider}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
              listingType={listingType}
              projectTransaction={projectTransaction}
              currentCustomer={currentCustomer}
              currentProvider={currentProvider}
              openCustomOfferModalButton={openCustomOfferModalButton}
            />

            {projectTransaction ? (
              <div className={css.oldConversationWrapper}>
                <NamedLink
                  name={isCustomer ? 'SaleDetailsPage' : 'OrderDetailsPage'}
                  params={{ id: projectTransaction.id.uuid }}
                >
                  <FormattedMessage id="TransactionPanel.oldConversation" />
                </NamedLink>
              </div>
            ) : null}

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            <div className={css.conversationAndMessageFormWrapper}>
              <div className={css.conversationWrapper}>
                <FeedSection
                  rootClassName={css.feedContainer}
                  currentTransaction={currentTransaction}
                  currentUser={currentUser}
                  fetchMessagesError={fetchMessagesError}
                  fetchMessagesInProgress={fetchMessagesInProgress}
                  initialMessageFailed={initialMessageFailed}
                  messages={messages}
                  oldestMessagePageFetched={oldestMessagePageFetched}
                  onOpenReviewModal={this.onOpenReviewModal}
                  onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
                  totalMessagePages={totalMessagePages}
                />
              </div>

              {showSendMessageForm ? (
                <SendMessageForm
                  formId={this.sendMessageFormName}
                  rootClassName={css.sendMessageForm}
                  currentUser={currentUser}
                  messagePlaceholder={sendMessagePlaceholder}
                  inProgress={sendMessageInProgress}
                  sendMessageError={sendMessageError}
                  onFocus={this.onSendMessageFormFocus}
                  onBlur={this.onSendMessageFormBlur}
                  onSubmit={this.onMessageSubmit}
                  onAttachmentSubmit={this.onAttachmentSubmit}
                  openCustomOfferModalButton={openCustomOfferModalButton}
                />
              ) : (
                <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
              )}
            </div>

            {stateData.showProjectSaleButtons ? (
              <div className={css.mobileActionButtons}>{projectActionButtons}</div>
            ) : null}
          </div>

          <div className={!this.state.txInfoOpen ? css.asideDesktop : css.asideDesktopHiddenMobile}>
            <div className={css.detailCard}>
              {listingType === 'project' || listingType === 'profile' ? (
                <ProjectDetailCardInfo
                  avatarWrapperClassName={css.avatarWrapperDesktop}
                  listingTitle={bookingPanelListingLink}
                  listingDescription={listingDescription}
                  provider={currentProvider}
                  isCustomer={isCustomer}
                  projectBidDescription={projectBidDescription}
                />
              ) : (
                <DetailCardImage
                  avatarWrapperClassName={css.avatarWrapperDesktop}
                  listingTitle={listingTitle}
                  image={firstImage}
                  provider={currentProvider}
                  isCustomer={isCustomer}
                />
              )}
              <div className={css.bookingDetailsMobile}>
                {!isTransactionFromApplication && bookingPanelListingLink}

                <BreakdownMaybe
                  transaction={currentTransaction}
                  transactionRole={transactionRole}
                />
              </div>
              {stateData.showBookingPanel ? (
                <>
                  <BookingPanel
                    className={css.bookingPanel}
                    titleClassName={css.bookingTitle}
                    isOwnListing={false}
                    listing={currentListing}
                    title={bookingPanelListingLink}
                    // subTitle={bookingSubTitle}
                    authorDisplayName={authorDisplayName}
                    provider={currentProvider}
                    authorStripeConnected={authorStripeConnected}
                    onSubmit={onSubmitBookingRequest}
                    onManageDisableScrolling={onManageDisableScrolling}
                    timeSlots={timeSlots}
                    fetchTimeSlotsError={fetchTimeSlotsError}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    currentUser={currentUser}
                  />
                  {isCustomer ? customOfferForm : null}
                </>
              ) : stateData.showProjectBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={false}
                  contactOnly={true}
                  showContactUser={false}
                  onContactUser={null}
                  unitType={unitType}
                  onSubmit={this.onProjectBid}
                  title={bookingPanelListingLink}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  provider={currentProvider}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  authorStripeConnected={authorStripeConnected}
                  currentUser={currentUser}
                  projectBidButtonText={projectBidButtonText}
                />
              ) : null}
              {currentTransaction.attributes.lastTransition !== TRANSITION_DECLINE_OFFER ? (
                <BreakdownMaybe
                  className={css.breakdownContainer}
                  transaction={currentTransaction}
                  transactionRole={transactionRole}
                  listing={currentListing}
                />
              ) : null}

              <div className={css.desktopActionButtons}>{saleButtons}</div>
              {acceptCustomOfferProModal}

              {stateData.showProjectSaleButtons ? (
                <div className={css.desktopActionButtons}>{projectActionButtons}</div>
              ) : null}
              {(stateData.headingState === 'delivered' ||
                stateData.headingState === 'declined' ||
                stateData.headingState === 'job-accepted') &&
              isCustomer &&
              currentListingType === 'service' &&
              !isListingClosed ? (
                <div className={css.newEnquiryButton}>
                  <h3>
                    <FormattedMessage
                      id="TransactionPanel.newEnquiryTitle"
                      values={{ providerName: authorDisplayName }}
                    />
                  </h3>
                  <Button onClick={handleNewEnquiryClick}>
                    <FormattedMessage id="TransactionPanel.newEnquiryButton" />
                  </Button>
                  {newEnquiry}
                </div>
              ) : null}
              {/* {(stateData.headingState === 'delivered' || stateData.headingState === 'declined') &&
              currentListingType === 'profile' &&
              !isCustomer &&
              !isProjectListingClosed ? (
                <div className={css.newEnquiryButton}>
                  <Button onClick={handleNewProjectEnquiryClick}>
                    <FormattedMessage id="TransactionPanel.newEnquiryButton" />
                  </Button>
                  {newProjectEnquiry}
                </div>
              ) : null} */}
            </div>
            {!hideTransactionProcessTimeline ? (
              <TransactionProcessTimeline
                stateData={stateData}
                currentTransaction={currentTransaction}
                intl={intl}
                isCustomer={isCustomer}
                isProOrInvoiceCustomer={isProOrInvoiceCustomer}
              />
            ) : null}
            <NeedHelpInfo arcticleId="7044544" />

            {/* {paymentStepsInfo} */}
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
        <Modal
          id="ProjectListingPage.projectBid"
          contentClassName={css.enquiryModalContent}
          isOpen={this.state.projectBidModalOpen}
          onClose={() => this.setState({ projectBidModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <ProjectBidForm
            className={css.enquiryForm}
            listingTitle={listingTitle}
            authorDisplayName={authorDisplayName}
            provider={currentProvider}
            sendEnquiryError={sendProjectBidError}
            onSubmit={this.onSubmitProjectBid}
            inProgress={sendProjectBidInProgress}
          />
        </Modal>
        <Modal
          id="TransactionPage.customOffer"
          isOpen={this.state.customOfferModalOpen}
          onClose={() => this.setState({ customOfferModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <CustomOfferForm
            formId="customOfferForm"
            rootClassName={css.enquiryForm}
            customOfferPlaceholder={customOfferPlaceholder}
            isProvider={isProvider}
            inProgress={sendCustomOfferInProgress}
            offerError={sendCustomOfferError}
            onSubmit={this.handleSendCustomOffer}
            isProOrInvoiceCustomer={isProOrInvoiceCustomer}
          />
        </Modal>
        <Modal
          id="TransactionPanel.taxInformation"
          contentClassName={css.enquiryModalContent}
          isOpen={this.state.taxInformationModalOpen}
          onClose={() => this.setState({ taxInformationModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.taxInformationModalContent}>
            <h2>
              <FormattedMessage id="TransactionPanel.taxInformationModalTitle" />
            </h2>
            <p>
              <FormattedMessage
                id="TransactionPanel.taxInformationModalText"
                values={{
                  a: chunks => (
                    <ExternalLink
                      key="linkToInstagram"
                      href="https://www.vero.fi/syventavat-vero-ohjeet/ohje-hakusivu/209369/raportoivan-alustaoperaattorin-tiedonantovelvollisuus-dac7/"
                    >
                      {chunks}
                    </ExternalLink>
                  ),
                }}
              />
            </p>
            <PrimaryButton
              className={css.taxInformationModalButton}
              onClick={() => {
                this.setState({ taxInformationModalOpen: false });
                history.push('/account/tax-information');
              }}
            >
              <FormattedMessage id="TransactionPanel.taxInformationModalButton" />
            </PrimaryButton>
          </div>
        </Modal>
        {typeof window === 'object' ? <ReactTooltip /> : null}
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(withRouter(TransactionPanelComponent));

export default TransactionPanel;
