import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import schoffaLogo from '../../assets/schoffaLogo.svg';
import tulusLogo from '../../assets/tulus-logo.svg';
import piirlaLogo from '../../assets/piirla-logo.png';
import peikkoLogo from '../../assets/peikko-group-logo.svg';
import olefitLogo from '../../assets/olefit-logo.svg';
import eerikkilaLogo from '../../assets/eerikkila-logo.svg';
import coregoLogo from '../../assets/corego-logo.png';
import mobalLogo from '../../assets/Mobal_Logo_Transparent symbol_Black.svg';
import strongestLogo from '../../assets/strongest-logo-black.svg';
import systamLogo from '../../assets/systam-logo.svg';
import zervantLogo from '../../assets/zervant-logo.svg';
import carbocultureLogo from '../../assets/carboculture-logo.svg';
import fundisLogo from '../../assets/fundis-logo.svg';

import css from './SectionCustomerLogos.css';

const MOBILE_REVIEW_BREAKPOINT = 720;

const SectionCustomerLogos = props => {
  const { toShow = [] } = props;

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMobileView(window.innerWidth < MOBILE_REVIEW_BREAKPOINT);

      const handleResize = () => {
        setIsMobileView(window.innerWidth < MOBILE_REVIEW_BREAKPOINT);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  const logoArray = [
    { id: 'schoffa', imgSrc: schoffaLogo, className: 'logoMedium' },
    { id: 'tulus', imgSrc: tulusLogo, className: 'logoMedium' },
    { id: 'piirla', imgSrc: piirlaLogo, className: 'logoMedium' },
    { id: 'peikko', imgSrc: peikkoLogo, className: 'logoMedium' },
    { id: 'olefit', imgSrc: olefitLogo, className: 'logoSmall' },
    { id: 'eerikkila', imgSrc: eerikkilaLogo, className: 'logoLarge' },
    { id: 'corego', imgSrc: coregoLogo, className: 'logoLarge' },
    { id: 'mobal', imgSrc: mobalLogo, className: 'logoMedium' },
    { id: 'strongest', imgSrc: strongestLogo, className: 'logoXSmall' },
    { id: 'systam', imgSrc: systamLogo, className: 'logoSmall' },
    { id: 'zervant', imgSrc: zervantLogo, className: 'logoSmall' },
    { id: 'carboculture', imgSrc: carbocultureLogo, className: 'logoXSmall' },
    { id: 'fundis', imgSrc: fundisLogo, className: 'logoMedium' },
  ];

  const filteredLogos =
    toShow.length > 0
      ? logoArray
          .filter(logo => toShow.includes(logo.id))
          .sort((a, b) => toShow.indexOf(a.id) - toShow.indexOf(b.id))
      : logoArray;

  const logosToRender = isMobileView ? filteredLogos.slice(0, 4) : filteredLogos;

  return (
    <div className={css.proLogoSection}>
      <div className={css.proLogosWrapper}>
        <div className={css.proLogosOurCustomers}>
          <FormattedMessage id="SectionCustomerLogos.ourCustomers" />
        </div>
        <div className={css.proLogosContainer}>
          {logosToRender.map(logo => (
            <img
              key={logo.id}
              src={logo.imgSrc}
              alt={`${logo.id} Logo`}
              className={`${css[logo.className]}`} // Combine base class with size class
              loading="lazy"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionCustomerLogos;
